<template>
  <!-- 增加修改页面 -->
  <div class="_dialog">
    <div class="_top">
      <i @click="close" style="cursor: pointer;" class="el-icon-d-arrow-left"></i>
      <span @click="close">{{ $t('StorageAllot.return') }}</span><!--返回-->
      <div style="width: 1px;height: 20px;background-color:#e5e6eb;"></div>
      <font>{{ $t('StorageChange.adjustment') }}</font><!--库存调整-->
    </div>
    <div style="height: calc(100vh - 250px);overflow-y: auto;">
      <el-form ref="form" label-position="left" :rules="rules" label-width="100px" input-width="100px" :model="StorageTiaoZDan">
        <div>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('StorageAllot.WarehouseName')" prop="WarehouseName">
                <el-input :placeholder="$t('StorageOutKu.choice')" v-model="StorageTiaoZDan.WarehouseName" @input="changeDraft($event, 'WarehouseName', 0)" readonly
                  class="input-with-select">
                  <el-button slot="append" icon="el-icon-thumb" @click="RoomClick(StorageTiaoZDan)"></el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('StorageChange.TiaoZTime')">
                <el-date-picker v-model="StorageTiaoZDan.TiaoZTime" @change="changeDraft($event, 'TiaoZTime', 0)" type="datetime" :clearable="false"
                  :placeholder="$t('AssetHandOverAdd.tips2')">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item :label="$t('StorageChange.TiaoZRemark')" v-if="allShow == false"> <!-- 调出描述 -->
                <el-input :readonly="allShow" maxlength="512" type="textarea" class="textarea" rows="3" v-model="StorageTiaoZDan.TiaoZRemark"
                  @input="changeDraft($event, 'TiaoZRemark', 0)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="tableBuuton">
        <div class="_filter">
          <div v-show="!allShow">
            <el-divider></el-divider>
            <el-button icon="el-icon-plus" class="button_primary" :disabled="StorageTiaoZDan.WarehouseName == ''" @click="AddGoods()">{{
              $t('StorageAllot.SelectGoods') }}</el-button><!-- 选择物资 -->
            <el-button class="button_info" icon="el-icon-delete" @click="DelGoods()" :disabled="goodsChecked">{{
            $t('StorageAllot.DeleteGoods')
          }}</el-button> <!-- 删除所选 -->
            <el-input v-model="batchNumber" v-if="BatchInku" type="number" :placeholder="$t('StorageAllot.BatchInputNews')" @input="BatchInput"
              style="width: 240px !important;"></el-input>
            <el-button class="button_info" icon="el-icon-edit" @click="AddBatch()">{{ $t('StorageAllot.BatchInput')
          }}</el-button> <!-- 批量输入 -->
            <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;">
                {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
          </div>
        </div>
      </div>
      <div style="position: relative;width: 100%;height: calc(100vh - 550px);">
        <el-table :key="itemKey" style="position: absolute;" border :data="ChangeNotes" width="88%" height="calc(100vh - 550px)" stripe @selection-change="handleSelectionChange1"
          ref="dialogList" :row-class-name="selectRowClassName1" @row-click="handleRowClick1" @header-dragend="headerDragend">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column :sortable="item.sort" v-for="(item, index) in TableData" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
            <template slot-scope="scope">
              <ImageView v-if="item.propName == 'HuoPinImage'" :url="scope.row.GoodsImage ? scope.row.GoodsImage : scope.row.HuoPinImage"></ImageView>

              <el-input v-else-if="item.propName == 'TiaoZCount'" class="_table_input" oninput="value=value.replace(/[^\d]/g,'')" v-model="scope.row.TiaoZCount"
                :data-rowindex="'TiaoZCount' + scope.$index" :ref="`inputRef${scope.$index + 1}`" @keydown.tab.native="focusTabInput(scope.$index + 1)"
                @keyup.enter.native="focusTabInput(scope.$index + 1, 'TiaoZCount')"></el-input>

              <el-input v-else-if="item.propName == 'TiaoZPrice'" class="_table_input" oninput="value=value.replace(/[^\d]/g,'')" v-model="scope.row.TiaoZPrice"
                :data-rowindex="'TiaoZPrice' + scope.$index" :ref="`inputRef${scope.$index + 1}`" @keydown.tab.native="focusTabInput(scope.$index + 1)"
                @keyup.enter.native="focusTabInput(scope.$index + 1, 'TiaoZPrice')"></el-input>

              <el-popover v-else-if="item.propName == 'TiaoZRemark'" placement="top-start" width="200" trigger="click">
                <span>{{ scope.row.TiaoZRemark }}</span>
                <span slot="reference">{{ scope.row.TiaoZRemark }}</span>
              </el-popover>
              <span v-else>
                {{ scope.row[item.propName] }}
              </span>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <img src="@/assets/img/empty.png" />
            <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
          </div>
        </el-table>

      </div>
    </div>
    <div class="button_Change">
      <span> {{ $t('StorageAllot.Count') }}{{ AllotKuNumber }} {{ $t('StorageAllot.SumMoney') }}{{ AllotTiaoZPrice
      }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close" class="button_info" style="margin-right: 7px">{{ $t('StorageAllot.cancel')
        }}</el-button>
        <el-button type="primary" class="button_primary" @click="append()">{{ $t('StorageAllot.confirm') }}</el-button>
      </div>
    </div>
    <DragTable v-if="TableOk" TableName="StorageTiaoZAdd" :show="TableOk" @message="Message_">
    </DragTable>
    <!-- 调出库房 -->
    <SelectWarehouse v-if="Roomtype" :type="Roomtype" @close="Roomtype = false" @addMember="addRoom" :DataList="RoomList">
    </SelectWarehouse>
    <!-- 物资组件 -->
    <SystemGoods v-if="gooodsType" :type="gooodsType" :warehouseid="StorageTiaoZDan.WarehouseID" :DataList="DataList" @close="gooodsType = false" @addGoods="addGoods">
    </SystemGoods>
    <ExportView v-if="upload" :state="upload" :uptype="uptype" :exportColumn="exportColumn" :error="errString" :errUrl="errUrl" :importUrl="`TemplateName=${viewtype}`"
      @close="upload = false" @clean="errString = '', errUrl = ''" @submit="ExportInput"></ExportView>
  </div>
</template>
  
<script>
import mixins from "@/mixins/index";
import {
  msgTips,
  successTips,
  getAllotDanStatus,
  GetStorageWarehouseTree,
  getAllotDetail,
  updateTime,
  addTiaoZDan,
  updateAllotDan,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,
  GetExportColumn,
  GetExportDownUrl,
  ImportData,
  GetStorageShelfPage
} from "@/api/user";
import draft from '@/mixins/draft';
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  props: {
    ChangeOpertion: {
      type: Object
    },
    show: {
      type: Boolean,
    },
    AllotID: {
      type: Number,
      default: 0
    },
    AllotTitle: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  name: "SettingView",
  components: {},
  mixins: [mixins, draft],
  data() {
    return {
      menutab: 0,
      Roomtype: false,
      InRoomtype: false,
      TableOk: false,
      RoomList: [],
      InRoomList: [],
      queryState: false,
      operated: true,
      delstate: false,
      checkstate: false,
      ID: "",
      level: "",
      allShow: false,
      addType: true,
      drawer: false,
      filterText: "",
      icon: "",
      TiaoZCount: "",
      title: "",
      currentPage: 1,
      fileList: "",
      Title: "",
      gooodsType: false,
      checked: true,
      BatchInku: false,
      goodsChecked: true,
      dialogFormVisible: false,
      tableData: [],
      TableData: [],
      ChangeNotes: [],
      DataList: [],
      TotalCount: 0,
      warehouseid: "",
      StorageTiaoZDan: {
        TiaoZDanID: "",
        CompanyID: "",
        TiaoZDanCode: "",
        WarehouseID: "",
        WarehouseName: "",
        Operator: "",
        TiaoZStatus: "",
        TiaoZRemark: "",
        TiaoZTime: new Date(),
        TiaoZCount: "",
        TiaoZMoney: "",
        AllCount: "",
        AllMoney: "",
        UpdateTime: "",
        CreateTime: ""
      },
      OperateType: "",
      data: [],
      warehouse: [],
      value: "",
      value1: "",
      value2: "",
      NotesID: "",
      receivedNum: 1,
      disreceive: true,
      loading: false,
      Times: null,
      options: [
        {
          label: this.$t('StorageAllot.unknown'),
          value: 0,
        },
        {
          label: this.$t('StorageAllot.confirm'),
          value: 1,
        },
        {
          label: this.$t('StorageAllot.Reject'),
          value: 2,
        },
        {
          label: this.$t('StorageAllot.delete'),
          value: 3,
        },
        {
          label: this.$t('StorageAllot.outline'),
          value: 4,
        },
        {
          label: this.$t('StorageAllot.Receive'),
          value: 5,
        },
        {
          label: this.$t('StorageAllot.Refusal'),
          value: 6,
        },
      ],
      rules: {
        WarehouseName: [
          { required: true, message: this.$t('StorageAllot.choice'), trigger: "blur" },
        ],
        InWarehouseName: [
          { required: true, message: this.$t('StorageAllot.choice'), trigger: "blur" },
        ],
      },
      multipleSelection: [],
      multipleSelection1: [],
      upload: false,
      uptype: '',
      allotID: '',
      itemKey: '',
      exportColumn: [],
      selectTable: [],
      ExportType: '',
      errString: "",
      errUrl: '',
      showdetail: false,
      viewtype: '',
      draftTitle: ['StorageTiaoZDan'],
      batchNumber: null,
      Outoptions: [],
      Inoptions: []
    };
  },
  computed: {
    AllotKuNumber() {
      let sum = 0;
      for (let item of this.ChangeNotes) {
        sum += parseInt(item.TiaoZCount || 0);
      }
      return sum;
    },
    AllotTiaoZPrice() {
      let sum = 0;
      let price;
      for (let item of this.ChangeNotes) {
        price = parseFloat(item.TiaoZCount * item.TiaoZPrice).toFixed(2);
        sum += parseFloat(price);
      }
      if (price == 'NaN') {
        sum = 0;
      }
      return sum;
    },
  },
  mounted() {
    this.getWarehouseTree();
    this.getStatus();
    this.getTableColumn();
    if (this.ChangeOpertion.Opertion == 'Update') {
      getAllotDetail({ AllotDanID: this.AllotID }).then(
        (res) => {
          if (res.Code == 0) {
            this.StorageTiaoZDan = res.Data;
            this.StorageTiaoZDan.TiaoZTime = updateTime(this.StorageTiaoZDan.TiaoZTime);
            this.ChangeNotes = res.Data.DetailList;
            this.addType = false;
          }
        }
      );
    }

    if (this.state) {
      this.StorageTiaoZDan = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
      this.getShelf('in');
      this.getShelf('out');
      this.ChangeNotes = JSON.parse(window.localStorage.getItem('StorageChangeNotes')) || [];
    }
    console.log(this.ChangeNotes);
    document.addEventListener('keydown', this.keyprevent);
    this.dialogFormVisible = this.show;
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyprevent)
  },
  created() {
    // 当页面刷新时，若本地存储中存的有表头数组信息，就用本地的。当然第一次本地是没有的
    if (sessionStorage.getItem("AllotTable")) {
      this.AllotTable = JSON.parse(sessionStorage.getItem("AllotTable"));
    }
  },
  methods: {
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'StorageTiaoZAdd' }).then(res => {
        if (res.Code == 0) {
          this.TableData = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      let lin_Data = this.ChangeNotes;
      this.ChangeNotes = lin_Data;
      this.getTableColumn();
      this.itemKey = Math.random();
      this.TableOk = false;
    },
    addRoom(e) {
      this.StorageTiaoZDan.WarehouseID = e.WarehouseID;
      this.StorageTiaoZDan.WarehouseName = e.WarehouseName;
      this.changeDraft(e.WarehouseID, 'WarehouseID', 0);
      this.changeDraft(e.WarehouseName, 'WarehouseName', 0);
      this.ChangeNotes = [];
      this.Roomtype = false;
    },
    //出库点击事件
    RoomClick(data) {
      this.RoomList = [];
      if (data) {
        this.Roomtype = true;
        this.RoomList.push(data.WarehouseID);
      }
    },
    InAddRoom(e) {
      this.StorageTiaoZDan.InWarehouseID = e.WarehouseID;
      this.StorageTiaoZDan.InWarehouseName = e.WarehouseName;
      this.changeDraft(e.WarehouseID, 'InWarehouseID', 0);
      this.changeDraft(e.WarehouseName, 'InWarehouseName', 0);
      this.InRoomtype = false;
    },

    //入库点击事件
    InRoomClick(data) {
      this.InRoomList = [];
      if (data) {
        this.InRoomtype = true;
        this.InRoomList.push(data.InWarehouseID);
      }
    },
    getShelf(type) {
      let form = {
        PageIndex: 0,
        PageSize: 100,
        WarehouseID: type == 'in' ? this.StorageTiaoZDan.InWarehouseID : this.StorageTiaoZDan.WarehouseID
      }
      GetStorageShelfPage(form).then(res => {
        if (res.Code == 0) {
          if (type == 'in') {
            this.Inoptions = res.Data;
          } else {
            this.Outoptions = res.Data;
          }
        }
      })
    },
    keyprevent(event) {
      if (event.keyCode === 9) {
        event.preventDefault()
      }
    },
    getColumn() {
      GetExportColumn({ ExportName: this.viewtype }).then(res => {
        if (res.Code == 0) {
          this.exportColumn = res.Data;
          this.upload = true;
        }
      })
    },
    ExportInput(value, state) {
      if (state == 'in') {
        let Url = value.upUrl
        ImportData({ Url, ImportName: this.viewtype }).then(res => {
          if (res.Code == 0) {
            this.errString = '';
            this.errUrl = '';
            successTips(res.Message)
          } else if (res.Code == 2) {
            this.errString = res.Message;
            this.errUrl = res.Data;
          }
        })
        this.upload = false;
      } else {
        let { ExportType } = value;
        let Head = JSON.stringify(value.Head)
        let SearchJson = JSON.stringify(this.form);
        GetExportDownUrl({ Head, ExportType, SearchJson, ExportName: this.viewtype }).then(res => {
          if (res.Code == 0) {
            let a = document.createElement('a');
            a.href = res.Data;
            this.upload = false;
            a.click();
            document.removeChild(a)
          }
        })
      }
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageTiaoZAdd', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    //清空表格数据
    close() {
      this.draftState[this.draftIndex] = false;
      if (this.ChangeNotes.length != 0) {
        window.localStorage.setItem('StorageChangeNotes', JSON.stringify(this.ChangeNotes))
      }
      this.$emit("close");
    },

    focusTabInput(rowIndex) {
      this.$nextTick(() => {
        this.$refs.dialogList.clearSelection();
        if (rowIndex < this.ChangeNotes.length) {
          this.$refs[`inputRef${parseInt(rowIndex + 1)}`][0].focus();
          this.$refs.dialogList.toggleRowSelection(this.ChangeNotes[rowIndex]);
        } else {
          this.$refs[`inputRef1`][0].focus();
          this.$refs.dialogList.toggleRowSelection(this.ChangeNotes[0]);
        }
      })
    },
    //获取库房下拉树
    getWarehouseTree() {
      GetStorageWarehouseTree().then((res) => {
        if (res.Code == 0) {
          this.warehouse = res.Data;
        }
      });
    },
    //批量填充
    AddBatch() {
      if (this.BatchInku == false) {
        this.BatchInku = true;
      } else {
        this.BatchInku = false;
      }
    },
    // getTimeArr() {
    //   getTimeRange().then((res) => {
    //     if (res.Code == 0) {
    //       this.Times = res.Data;
    //       this.form.StartTime = res.Data[0];
    //       this.form.EndTime = res.Data[1];
    //     }
    //   });
    // },
    getStatus() {
      getAllotDanStatus().then((res) => {
        if (res.Code == 0) {
          if (res.Data) {
            this.options = [];
            for (let item of res.Data) {
              this.options.push({
                label: item.name,
                value: item.value,
              });
            }
          }
        }
      });
    },
    selStatus(e) {
      this.form.OutKuStatus = e;
      for (let item of this.options) {
        if (item.value == e) {
          this.form.AllotStatusName = item.label;
        }
      }
    },

    //重置
    SelectRemove() {
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.form.PageIndex = 0;
      this.form.PageSize = 20;
    },


    // 选择资产
    async addGoods(e) {
      for (let i = 0; i < e.length; i++) {
        this.ChangeNotes.push(e[i]);
      }
      let arr1 = this.unique(this.ChangeNotes);
      this.ChangeNotes = [];
      this.ChangeNotes = arr1;
      window.localStorage.setItem('StorageChangeNotes', JSON.stringify(e));
      this.gooodsType = false;
    },
    //去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.GoodsID) && res.set(arr.GoodsID, 1));
    },
    //增加资产
    AddGoods() {
      this.DataList = [];
      if (this.ChangeNotes != null) {
        for (let i = 0; i < this.ChangeNotes.length; i++) {
          this.DataList.push(this.ChangeNotes[i].GoodsID);
        }
      }
      this.gooodsType = true;
    },
    SetOutWarehouse(e) {
      this.changeDraft(e.label, 'WarehouseName', 0);
      if (!e) {
        this.StorageTiaoZDan.WarehouseID = "";
        return;
      }

      this.addType = false;
      this.StorageTiaoZDan.WarehouseID = e.id;
      this.getShelf('out');
      this.StorageTiaoZDan.WarehouseName = e.label;
      this.ChangeNotes = [];
    },
    SetInWarehouse(e) {
      if (!e) {
        this.StorageTiaoZDan.InWarehouseID = "";
        return;
      }
      this.addType = false;
      this.StorageTiaoZDan.InWarehouseID = e.id;
      this.StorageTiaoZDan.InWarehouseName = e.label;
      // this.getShelf('in');
      this.changeDraft(e.label, 'InWarehouseName', 0)
    },
    append() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let list = this.ChangeNotes;
          console.log(list);
          if (list.length == 0) {
            msgTips(this.$t('StorageAllot.msgTips'));
            return;
          }
          let form = {
            WarehouseID: this.StorageTiaoZDan.WarehouseID,
            TiaoZTime: this.StorageTiaoZDan.TiaoZTime,
            TiaoZRemark: this.StorageTiaoZDan.TiaoZRemark,
            GoodsDetail: "",
          };
          let str = [];
          for (let item of list) {
            let { GoodsID, HuoPinID, TiaoZCount, GoodsRemark, TiaoZPrice } = item;
            str.push({
              GoodsID,
              HuoPinID,
              TiaoZCount,
              GoodsRemark,
              TiaoZPrice
            });
          }
          form.GoodsDetail = JSON.stringify(str);
          if (this.ChangeOpertion.Opertion == 'Add') {
            addTiaoZDan(form).then((res) => {
              if (res.Code == 0) {
                successTips(res.Message);
                this.dialogFormVisible = false;
                this.value = "";
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                window.localStorage.setItem('StorageChangeNotes', null);
                this.draftState[this.draftIndex] = false;
                this.ChangeNotes = [];
                this.$emit('message', res.Code);
                this.close();
              }
            });
          } else {
            updateAllotDan({
              AllotDanID: this.StorageTiaoZDan.AllotDanID,
              ...form,
            }).then((res) => {
              if (res.Code == 0) {
                successTips(res.Message);
                this.dialogFormVisible = false;
                this.value = "";
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                window.localStorage.setItem('StorageChangeNotes', null);
                this.draftState[this.draftIndex] = false;
                this.ChangeNotes = [];
                this.$emit('message', res.Code);
                this.close();
              }
            });
          }
        }
      });
    },

    reset() {
      for (let i in this.StorageTiaoZDan) {
        this.StorageTiaoZDan[i] = "";
      }
      this.value = "";
      this.value1 = "";
      this.value2 = "";
      this.ChangeNotes = [];
    },
    //点击行触发，选中或不选中复选框
    handleRowClick(row) {
      if (row.AllotStatus == 5 || row.AllotStatus == 6) {
        return;
      }
      this.$refs.tableList.toggleRowSelection(row); //设置复选框为选中状态
    },
    //点击背景色
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.AllotDanID == row.AllotDanID) {
          color = "warning-row";
        }
      });
      return color;
    },

    handleRowClick1(row) {
      // this.$refs.dialogList.clearSelection();
      // let index = this.multipleSelection.findIndex(item => {
      //   // 判断已选数组中是否已存在该条数据
      //   return item.GoodsID == row.GoodsID
      // });
      // if (index == -1) {
      //   // 如果未存在，设置已选状态，并在list中添加这条数据
      //   this.$refs.dialogList.toggleRowSelection(row, true); //设置复选框为选中状态
      // } else {
      //   // 如果已存在，设置未选状态，并在list中删除这条数据
      //   this.$refs.dialogList.toggleRowSelection(row, false); //设置复选框为未选状态
      // }
      this.$refs.dialogList.toggleRowSelection(row); //设置复选框为选中状态
    },
    selectRowClassName1({ row }) {
      var color = "";
      this.multipleSelection1.forEach(item => {
        if (item.GoodsID == row.GoodsID) {
          color = "warning-row";
        }
      });
      return color;
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val;
      if (val.length != 0) {
        this.goodsChecked = false;
        this.selectTable = val;
      } else {
        this.goodsChecked = true;
      }
      if (val.length == 1) {
        this.NotesID = val[0].GoodsID;
      }
    },
    // 删除货品
    DelGoods() {
      for (let i = 0; i < this.selectTable.length; i++) {
        let list = JSON.parse(JSON.stringify(this.ChangeNotes));
        this.ChangeNotes = list.filter((item) => item.GoodsID != this.selectTable[i].GoodsID);
        window.localStorage.setItem('StorageChangeNotes', JSON.stringify(this.ChangeNotes));
      }
    },

    BatchInput(e) {
      let list = this.ChangeNotes.map(item => {
        item.TiaoZCount = e;
        return item
      })
      this.ChangeNotes = JSON.parse(JSON.stringify(list));
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__error {
  padding-top: 39px !important;
}

.button_Change {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 0 0 !important;
}

::v-deep .el-button[data-v-f6961be4] {
  margin-right: 0px !important;
}

.el-divider--vertical {
  height: 2em;
  margin-left: 30px;
}

.dialog-footer {
  float: right;
}

form.el-form.el-form--label-left {
  margin-top: 24px;
}

.input-with-select.el-input.el-input-group.el-input-group--append {
  // width: 330px;
  width: calc(100% - 50px) !important;
}

._dialog {
  width: 100%;
  height: calc(100vh - 140px);
  // padding: 0 20px 20px;
  background-color: #fff;

  ._top {
    height: 48px;
    display: flex;
    align-items: center;

    span {
      color: #4e5969;
      font-size: 14px;
      margin: 0 20px 0 5px;
      cursor: pointer;
    }

    font {
      font-size: 16px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 160px;
      height: 160px;
    }

    span {
      line-height: 30px;
    }

    font {
      color: #5c90ff;
      cursor: pointer;
    }
  }

  ._footer {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

._top {
  height: 48px;
  display: flex;
  align-items: center;

  span {
    color: #4e5969;
    font-size: 14px;
    margin: 0 20px 0 5px;
    cursor: pointer;
  }

  font {
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
  }
}

.SearchName {
  margin-right: 0% !important;
}

.textarea {
  // width: 75% !important;
  width: calc(100% - 50px) !important;
}

.el-input {
  // width: 75% !important;
  width: calc(100% - 50px) !important;
}

/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

.el-link {
  margin-right: 30px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

._table_input {
  border-bottom: 1px solid #eee;

  /deep/.el-input__inner {
    border: 1;
    text-align: center;
  }
}

.el-table .el-table__cell .el-input {
  padding: 0px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          // line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

._filter {
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
    float: right;
    line-height: 45px;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  bottom: 100px;
  margin-top: 15%;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.el-icon-refresh {
  color: #409eff;
}

.filter_box {
  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        width: 300px !important;

        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}
</style>