import Vue from "vue";
import Vuex from "vuex";
import { setCookie, getCookie } from "../api/cookie";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebarMenuList: [],
    loading: false,
    token: getCookie("token") || "",
    menulist: getCookie("menulist"),
    // 库存
    storage: getCookie("storage"),
    // 财务
    financial: getCookie("financial"),
    // 资产
    asset: getCookie("asset"),
    basic: getCookie("basic"),
    phone: "",
    userinfo: window.sessionStorage.getItem("user") || {},
    // menu:getCookie('menu') || {},
    breadList: JSON.parse(window.sessionStorage.getItem("breadList")) || [],
    company: JSON.parse(window.sessionStorage.getItem("company")) || {},
  },
  getters: {
    Token: (state) => state.token,
    Loading: (state) => state.loading,
    BreadList: (state) => state.breadList,
    AdminCompany: (state) => state.company,
  },
  mutations: {
    Menu(state, list) {
      state.sidebarMenuList = JSON.stringify(list);
      sessionStorage.setItem("menu", JSON.stringify(list));
    },
    register(state, phone) {
      state.phone = phone;
    },
    SET_TOKEN(state, token) {
      let cookie = {
        token: token,
      };
      setCookie(cookie, 1);
      // state.token = token;
    },
    userinfo(state, userinfo) {
      state.userinfo = userinfo;
      window.sessionStorage.setItem("user", userinfo);
    },
    SET_LOADING(state, type) {
      state.loading = type;
    },
    SET_BREAD(state, breadList) {
      state.breadList = breadList;
      window.sessionStorage.setItem("breadList", JSON.stringify(breadList));
    },
    SET_COMPANY(state, company) {
      state.company = company;
      window.sessionStorage.setItem("company", JSON.stringify(company));
    },
  },
  actions: {},
  modules: {},
});
