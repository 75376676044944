<template>
  <el-dialog :title="WarehouseTitle" center :visible.sync="dialogFormVisible" @close="close">
    <el-form ref="form" label-position="left" label-width="100px" input-width="100px" :rules="rules" :model="StorageWarehouse">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('StorageWarehouse.WarehouseCode')"> <!--库房编号-->
            <el-input :placeholder="$t('StorageWarehouse.WarehouseCode')" maxlength="32" show-word-limit v-model="StorageWarehouse.WarehouseCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageWarehouse.WarehouseName')" prop="WarehouseName"> <!--仓库名称-->
            <el-input :placeholder="$t('StorageWarehouse.WarehouseName')" maxlength="32" show-word-limit v-model="StorageWarehouse.WarehouseName"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="$t('StorageWarehouse.SuperiorWarehouse')"> <!--上级仓库-->
            <el-input readonly="" show-word-limit v-model="WarehouseText"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageWarehouse.Department')" prop="BelongDeptName">
            <el-input v-model="StorageWarehouse.BelongDeptName" @input="changeDraft($event, 'BelongDeptName', 0)" readonly class="input-with-select">
              <el-button slot="append" icon="el-icon-thumb" @click="DeptClick(StorageWarehouse)"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item :label="$t('StorageWarehouse.ISShared')"> <!--是否共享-->
        <el-radio v-model="StorageWarehouse.ISShared" :label="1">{{$t('StorageWarehouse.FullSharing')}}</el-radio><!--完全共享-->
        <el-radio v-model="StorageWarehouse.ISShared" :label="2">{{$t('StorageWarehouse.ShareSubordinates')}}</el-radio><!--共享到下级-->
        <el-radio v-model="StorageWarehouse.ISShared" :label="3">{{$t('StorageWarehouse.DoNotShare')}}</el-radio><!--不共享-->
      </el-form-item>
      <el-form-item :label="$t('StorageWarehouse.WarehouseRemark')"><!--仓库描述-->
        <el-input type="textarea" rows="5" maxlength="512" show-word-limit v-model="StorageWarehouse.WarehouseRemark"></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="close()" class="button_info">{{$t('StorageWarehouse.cancel')}}</el-button><!--取消-->
      <!-- 添加组织 -->
      <el-button type="primary" class="button_primary" @click="append()">{{$t('StorageWarehouse.confirm')}} </el-button><!--确定-->
    </div>
    <!-- 部门组件 -->
    <SelectDept v-if="DeptType" :type="DeptType" @close="DeptType = false" @addMember="addDept" :DataList="DeptList"></SelectDept>
  </el-dialog>
</template>
  
  <script>
import {
  successTips,
  GetStorageWarehouse,
  AddStorageWarehouse,
  UpdateStorageWarehouse,
  GetBasicDeptTree,
  GetExportColumn,
} from "@/api/user";
import mixins from '@/mixins/index'

export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  props: {
    show: {
      type: Boolean,
    },
    RoomOpertion: {
      type: Object
    },
    pid: {
      type: Number,
      default: 0
    },
    RoomID: {
      type: Number,
      default: 0
    },
    WarehouseTitle: {
      type: String,
      default: ''
    },
    RooName: {
      type: String,
      default: ''
    }

  },
  name: "SettingView",
  components: {},
  mixins: [mixins],
  data() {
    return {
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      filterText: "",
      icon: '',
      title: "",
      Status: '',

      WarehouseText: '',
      treeclick: true,
      currentPage: 1,
      Title: "",
      TitleName: '',
      disabledText: this.$t('StorageWarehouse.Disabled'),
      loading: false,
      checked: true,
      dialogFormVisible: false,
      SearchName: '',
      TotalCount: 0,
      StorageWarehouseType: '',
      tableData: [],
      DeptList: [],
      form: {
        WarehouseID: 0,
        SearchName: '',
        PageSize: 20,
        PageIndex: 0,
        StartTime: '',
        EndTime: ''
      },
      DeptName: '',
      StorageWarehouse: {
        WarehouseID: '',
        WarehouseCode: '',
        WarehouseName: '',
        ParentWarehouseID: '',
        ParentWarehouseID1: '',
        ParentWarehouseID2: '',
        ParentWarehouseID3: '',
        ParentWarehouseID4: '',
        ParentWarehouseID5: '',
        ParentWarehouseID6: '',
        ParentWarehouseID7: '',
        ParentWarehouseID8: '',
        ParentWarehouseID9: '',
        ParentWarehouseID10: '',
        WarehouseLevel: '',
        WarehouseType: '',
        BelongDeptID: '',
        BelongDeptName: '',
        ISShared: 1,
        DeptName: '',
        WarehouseStatus: '',
        WarehouseRemark: ''
      },
      // 表头数据
      WarehouseTable: [
        {
          propName: "WarehouseCode",
          labelName: this.$t('StorageWarehouse.WarehouseCode'),//仓库编号
          width: "170",
        },
        {
          propName: "WarehouseName",
          labelName: this.$t('StorageWarehouse.WarehouseName'),//仓库名称
          width: "auto",
        },
        {
          propName: "BelongDeptName",
          labelName: this.$t('StorageWarehouse.Department'),//所属部门
          width: "auto",
        },
        {
          propName: "ISSharedName",
          labelName: this.$t('StorageWarehouse.ISShared'),//是否共享
          width: "auto",
        },
        {
          propName: "WarehouseStatus",
          labelName: this.$t('StorageWarehouse.WarehouseStatus'),//状态
          width: "auto",
        }
      ],
      BelongDeptID: [],
      data: [],
      rules: {
        WarehouseCode: [
          { required: true, message: this.$t('StorageWarehouse.PleaseEnter'), trigger: 'blur' }
        ],
        WarehouseName: [
          { required: true, message: this.$t('StorageWarehouse.PleaseEnter'), trigger: 'blur' }
        ],
        DeptName: [
          { required: true, message: this.$t('StorageWarehouse.PleaseEnter'), trigger: 'blur' }
        ],
        WarehouseRemark: [
          { required: true, message: this.$t('StorageWarehouse.PleaseEnter'), trigger: 'blur' }
        ]
      },
      multipleSelection: [],
      upload: false,
      DeptType: false,
      uptype: '',
      exportColumn: [],
      ExportType: '',
      errString: "",
      errUrl: '',
      viewtype: ''
    };
  },
  mounted() {
    console.log(this.WarehouseTitle);
    if (this.RoomOpertion.Opertion == 'Update') {
      if (this.RooName == 'NodeAcquisition') {
        this.StorageWarehouse.WarehouseID = this.RoomID;
      }
      if (this.pid == 0) {
        this.WarehouseText = this.$t('StorageWarehouse.NoSuperior');
      } else if (this.pid != 0 && this.pid != null) {
        GetStorageWarehouse({ WarehouseID: this.pid }).then((res) => {
          if (res.Data) {
            this.WarehouseText = res.Data.WarehouseName;
          } else {
            this.WarehouseText = this.$t('StorageWarehouse.NoSuperior');
          }
        });
      } else {

        GetStorageWarehouse({ WarehouseID: this.RoomID }).then((res) => {
          if (res.Data) {
            this.WarehouseText = res.Data.WarehouseName;
          } else {
            this.WarehouseText = this.$t('StorageWarehouse.NoSuperior');
          }
        });
      }
      GetStorageWarehouse({ WarehouseID: this.RoomID }).then((res) => {
        if (res.Code == 0) {
          this.StorageWarehouse = res.Data;
          this.StorageWarehouse.DeptName = res.Data.BelongDeptName;
          this.DeptName = res.Data.BelongDeptName;
        }
      });
    } else {
      if (this.RooName == 'NodeAcquisition') {
        this.StorageWarehouse.WarehouseID = this.RoomID;
      } else if (this.RooName == 'FormAcquisition') {
        this.StorageWarehouse.WarehouseID = this.Roomid;
      }
      if (this.pid == 0) {
        this.WarehouseText = this.$t('StorageWarehouse.NoSuperior');
      } else if (this.pid != 0 && this.pid != null) {
        GetStorageWarehouse({ WarehouseID: this.pid }).then((res) => {
          if (res.Data) {
            this.WarehouseText = res.Data.WarehouseName;
          } else {
            this.WarehouseText = this.$t('StorageWarehouse.NoSuperior');
          }
        });
      } else {
        GetStorageWarehouse({ WarehouseID: this.RoomID }).then((res) => {
          if (res.Data) {
            this.WarehouseText = res.Data.WarehouseName;
          } else {
            this.WarehouseText = this.$t('StorageWarehouse.NoSuperior');
          }
        });
      }
    }
    this.getBasicDeptTree();
    this.dialogFormVisible = this.show;
  },
  created() {
    // 当页面刷新时，若本地存储中存的有表头数组信息，就用本地的。当然第一次本地是没有的
    if (sessionStorage.getItem("WarehouseTable")) {
      this.WarehouseTable = JSON.parse(sessionStorage.getItem("WarehouseTable"));
    }
  },
  methods: {

    //部门组件事件
    addDept(e) {
      this.StorageWarehouse.BelongDeptID = e.DeptID;
      this.StorageWarehouse.BelongDeptName = e.DeptName;
      this.DeptType = false;
    },
    //部门点击事件
    DeptClick(data) {
      this.DeptList = [];
      if (data) {
        this.DeptType = true;
        this.DeptList.push(data.BelongDeptID);
      }
    },
    // 清空选项后
    handleClear() {
      this.StorageWarehouse.BelongDeptID = "";
    },
    getColumn() {
      GetExportColumn({ ExportName: this.viewtype }).then(res => {
        if (res.Code == 0) {
          this.exportColumn = res.Data;
          this.upload = true;
        }
      })
    },
    SetOutWarehouse(e) {
      this.StorageWarehouse.BelongDeptID = e.id;
      this.StorageWarehouse.DeptName = e.label;
    },
    //清空表格数据
    close() {
      this.$emit("close")
    },


    //获取树形图
    getBasicDeptTree() {
      GetBasicDeptTree().then((res) => {
        if (res.Code == 0) {
          this.BelongDeptID = res.Data;
        }
      });
    },

    //增改
    append() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.RoomOpertion.Opertion == 'Add') {
            this.StorageWarehouse.WarehouseType = 1;
            if (this.RooName == 'NodeAcquisition') {
              this.StorageWarehouse.ParentWarehouseID = this.RoomID;
            } else if (this.RooName == 'FormAcquisition') {
              this.StorageWarehouse.ParentWarehouseID = this.Roomid;
            }
            AddStorageWarehouse(this.StorageWarehouse).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  this.dialogFormVisible = false; //弹窗页面是否开启
                  this.$emit('message', res.Code);
                }
              }
            );
          } else if (this.RoomOpertion.Opertion == 'Update') {
            this.StorageWarehouse.WarehouseType = 1;
            if (this.RooName == 'NodeAcquisition') {
              this.StorageWarehouse.ParentWarehouseID = this.RoomID;
            } else if (this.RooName == 'FormAcquisition') {
              this.StorageWarehouse.ParentWarehouseID = this.Roomid;
            }
            UpdateStorageWarehouse(this.StorageWarehouse).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  this.dialogFormVisible = false; //弹窗页面是否开启
                  this.$emit('message', res.Code);
                }
              }
            );
          }
        }
      });
    },
    //获取每页数量
    handleSizeChange(val) {
      this.form.PageSize = val; //每页数量
      this.getDataList();
    },
    //获取页数
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);//第几页
      this.getDataList();
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    Search() { },
    handleRowClick(row) {
      this.$refs.tableList.toggleRowSelection(row); //设置复选框为选中状态
    },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.WarehouseID == row.WarehouseID) {
          color = "warning-row";
        }
      });
      return color;
    },
  },
};
  </script>
  
<style lang="scss" scoped>
::v-deep .el-dialog--center .el-dialog__footer {
  text-align: right !important;
}
.el-form-item {
  margin-left: 22px;
}
.treeTable {
  overflow: auto;
  max-height: 650px;
}
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .right-link {
      float: right;
      line-height: 38px;
    }

    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 75%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}
</style>