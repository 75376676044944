<template>
    <el-input v-model="values" :type="formData.type" :readonly="onlyRead" @input="$emit('input', values)"
        style="width: calc(100% - 50px);" :placeholder="formData.placeholder" :maxlength="formData.maxlength"
        show-word-limit></el-input>
</template>
<script>
export default {
    props: {
        formData: {
            type: Object
        },
        onlyRead: {
            type: Boolean,
            default: true
        },
        value: {
            type: [Number, String]
        }
    },
    watch: {
        value: {
            handler(val) {
                console.log(val);
                this.values = val;
            },
            immediate: true
        }
    },
    data() {
        return {
            values: this.value,
        }
    },
    methods: {
        getWidth() {
            if (this.formData.col == 24 || this.onlyRead) return true;
            return false;
        }
    }
}
</script>
<style lang="scss" scoped></style>