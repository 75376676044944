<template>
  <el-dialog :visible.sync="state" width="1000px" :title="StartTimeText" style="margin: 0;" @close="close" height="800px" top="8vh" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_right">
          <div class="table">
            
            <div class="head_line">
              <div>
                <el-input type="text" v-model="form.SearchName" ></el-input>
                <el-button class="button_primary" icon="el-icon-search" @click="getData()">{{ $t('BasicDept.search') }}</el-button><!--搜索-->
              </div>

            </div>
            <div class="content_table">
              <div class="_filter">
              </div>
              <el-table border :data="tableData" height="calc(100vh - 455px)" v-loading="loading" stripe ref="tableList">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="请求类型" prop="M" min-width="100px"></el-table-column>
                <el-table-column label="登录Token" prop="T" min-width="280px"></el-table-column>
                <el-table-column label="请求" prop="G" min-width="200px">
                  <template slot-scope="scope">
                    <el-popover placement="top-start" width="200" trigger="click">
                      <span>{{ scope.row.G }}</span>
                      <span slot="reference">{{ scope.row.G }}</span>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="参数" prop="Q" min-width="200px">
                  <template slot-scope="scope">
                    <el-popover placement="top-start" width="200" trigger="click">
                      <span>{{ scope.row.Q }}</span>
                      <span slot="reference">{{ scope.row.Q }}</span>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="回复" prop="R" min-width="200px">
                  <template slot-scope="scope">
                    <el-popover placement="top-start" width="200" trigger="click">
                      <span>{{ scope.row.R }}</span>
                      <span slot="reference">{{ scope.row.R }}</span>
                    </el-popover>
                  </template>
                </el-table-column>

                <el-table-column label="来源IP" prop="IP" min-width="200px"></el-table-column>
                <el-table-column label="请求耗时" prop="QRTimeLen" min-width="200px"></el-table-column>
                <el-table-column label="请求时间" prop="StartTimeText" min-width="200px"></el-table-column>
                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
                </div>
              </el-table>

            </div>
            <div class="component_footer">
              <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(form.PageIndex + 1)"
                :page-sizes="[20, 50, 100]" :page-size="20" layout="total, prev, pager, next, jumper,sizes" :total="TotalCount">
              </el-pagination>
              <div class="_button">
                <el-button class="button_info" @click="close">{{ $t('AssetAllotAdd.quit')}}</el-button>
                <el-button class="button_primary" @click="addGoods()"> {{ $t('AssetAllotAdd.query') }} </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>
  
<script>
import {
  GetLoggerList
} from "@/api/user";
import mixins from "@/mixins";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  props: {
    StartTime: {
      type: String,
      default() {
        return '';
      }
    },
    show: {
      type: Boolean,
    },
    CompanyID: {
      type: [Number, String]
    }
  },
  components: {},
  mixins: [mixins],
  data() {
    return {
      filterText: "",
      tableData: [],
      TotalCount: 0,
      form: {
        CompanyID: 0,
        PageIndex: 0,
        PageSize: 20,
        StartTime: '',
        EndTime: '',
        SearchName: ''
      },
      StartTimeText:'',
      loading: false,
      state: false
    };
  },

  mounted() {
    this.state = this.show;
    this.StartTimeText = "操作详情："+this.StartTime;
    this.form.CompanyID = this.CompanyID;
    this.form.StartTime = this.StartTime;
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      GetLoggerList(this.form).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.tableData = res.Data;
          this.TotalCount = res.TotalCount;
          this.loading = false;
        } else {
          this.loading = false;
          this.tableData = [];
          this.TotalCount = 0;
        }
      });
    },
    close() {
      this.$emit('close')
    },
    addGoods() {
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.form.PageSize = val;
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);
      this.getData();
    },
  },
};
</script>
  
<style lang="scss" scoped>
._button {
    margin-top: 27px;
}
::v-deep .el-dialog__headerbtn {
  top: 15px !important;
}
.but_add {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  padding: 0px 20px !important;
}
/deep/.el-dialog {
  height: 650;
  overflow-y: auto;
}
/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}
.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  position: relative;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 700px);
      padding: 10px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 700px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        .el-input {
          width: 250px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}
.dialog-footer {
  text-align: right;
}
._filter {
  height: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}
</style>