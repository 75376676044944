//表单类型设置
import i18n from "@/lang";
const formCategory = [
  {
    formType: "input", //表单类型：暂时支持输入框input(number，textarea)，后续考虑增加其他表单
    type: "text", //对应text、textarea、number
    formName: i18n.t("FormEdit.TextInput"), //表单编号
    formTitle: i18n.t("FormEdit.TextInput"), //表单标题
    fileID: "", //表单ID(后端生成)
    props: {}, //配置
    style: {
      width: "100%",
    }, //样式
    isRequired: false, //是否必填(关联校验规则)
    validate: [], //校验规则
    options: [], //下拉列表的自动编辑
    hidden: false, //是否隐藏
    readonly: true, //是否只读
    disabled: false, //是否禁用
    children: [], //
    value: null, //关联值
    clearable: "", //是否可清除
    placeholder: i18n.t("FormEdit.PleaseEnter"), //占位符提示
    maxlength: "",
    col: 24,
  },
  {
    formType: "input",
    type: "textarea",
    formName: i18n.t("FormEdit.TextareaInput"),
    formTitle: i18n.t("FormEdit.TextareaInput"),
    fileID: "",
    props: {},
    style: {
      width: "100%",
    },
    isRequired: false,
    validate: [],
    options: [],
    hidden: false,
    readonly: true,
    disabled: false,
    children: [],
    value: null,
    clearable: "",
    placeholder: i18n.t("FormEdit.PleaseEnter"),
    maxlength: "",
    col: 24,
  },
  {
    formType: "input",
    type: "number",
    formName: i18n.t("FormEdit.NumberInput"),
    formTitle: i18n.t("FormEdit.NumberInput"),
    fileID: "",
    props: {},
    style: {
      width: "100%",
    },
    isRequired: false,
    validate: [],
    options: [],
    hidden: false,
    readonly: true,
    disabled: false,
    children: [],
    value: null,
    clearable: "",
    placeholder: i18n.t("FormEdit.PleaseEnter"),
    maxlength: "",
    col: 24,
  },
];

export default formCategory;
