<template>
  <el-dialog :title="$t('CategoryList.title')" :visible="visible" top="8vh" @close="closed" width="65%" custom-class="_dialog_height" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_left" v-loading="loading2">
          <div class="content" v-show="tabs == 0">
            <el-input :placeholder="$t('CategoryList.filterText')" v-model="filterText">
            </el-input>
            <el-tree class="treeTable" :data="dataTree" node-key="id" ref="tree" default-expand-all :expand-on-click-node="false" :filter-node-method="filterNode"
              @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node,data }">
                <span style="font-size: 15px;" :style="CategoryID == data.id ? 'color: #165DFF;' : ''"><i
                    :class="data.children.length != 0 ? 'el-icon-price-tag' : 'el-icon-house'"></i> {{ node.label
                    }}</span>
                <el-dropdown trigger="click" placement="right" width="160" v-if="data.id != 0">
                  <span class="el-dropdown-link">
                    <i class="el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus" @click.native="addCategory(data, 0), ID = data.pid">{{
                      $t('CategoryList.addsame') }}</el-dropdown-item><!--新增同级种类-->
                    <el-dropdown-item icon="el-icon-plus" @click.native="addCategory(data, 1), ID = data.id">{{
                      $t('CategoryList.addsub') }}</el-dropdown-item><!--增加下级种类-->
                    <!-- <el-dropdown-item icon="el-icon-edit" @click.native="updateCategory(data)">{{ $t('CategoryList.updateType') }}</el-dropdown-item> --><!--修改-->
                    <!-- <el-dropdown-item icon="el-icon-delete" @click.native="deleteStorageCategory(data)">{{ $t('CategoryList.deleteType') }}</el-dropdown-item> --><!--删除-->
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </el-tree>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="content_right">
          <div class="table">
            <span class="title">{{ title }}</span>
            <div class="head_line">
              <div>
                <el-input type="text" :placeholder="$t('CategoryList.tips8')" v-model="form.SearchName">
                </el-input>
                <el-button class="button_primary" icon="el-icon-search" @click="getStorageHuoPin()">{{ $t('BasicDept.search') }}</el-button><!--搜索-->
              </div>
              <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
                  {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
            </div>
            <div class="content_table">
              <el-table border :data="tableData" height="420px" v-loading="loading" stripe @selection-change="handleSelectionChange" :row-class-name="selectRowClassName"
                ref="tableList" @row-click="handleRowClick" @header-dragend="headerDragend">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
                  <template slot-scope="scope">
                    <ImageView v-if="item.propName == 'HuoPinImage'" :url="scope.row.HuoPinImage"></ImageView>
                    <span v-else>
                      {{ scope.row[item.propName] }}
                    </span>
                  </template>
                </el-table-column>
                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('StorageGoods.none') }}
                    <font @click="addHuoPin()">{{ $t('CategoryList.add') }}</font>~
                  </span>
                </div>
              </el-table>
            </div>
            <div class="component_footer">
              <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(form.PageIndex + 1)"
                :page-sizes="[20, 50, 100]" :page-size="form.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
              </el-pagination>
            </div>
            <el-divider></el-divider>
            <div class="_button">
              <el-button class="button_info" @click="closed">{{ $t('AssetAllotAdd.quit')}}</el-button>
              <el-button class="button_primary" @click="queyrHuoPin()"> {{ $t('AssetAllotAdd.query') }} </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 增加修改页面 -->
      <el-dialog :title="Title" :visible.sync="dialogFormVisible" center append-to-body>
        <el-form ref="dialog" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="StorageCategory" style="margin-left:100px;">
          <el-form-item :label="$t('CategoryList.CategoryCode')">
            <el-input :placeholder="$t('CategoryList.tips1')" maxlength="32" show-word-limit v-model="StorageCategory.CategoryCode"></el-input>
          </el-form-item>
          <el-form-item :label="$t('CategoryList.CategoryText')">
            <el-input :placeholder="$t('CategoryList.tips1')" v-model="CategoryText" readonly></el-input>
          </el-form-item>
          <el-form-item :label="$t('CategoryList.CategoryName')" prop="CategoryName">
            <el-input :placeholder="$t('CategoryList.tips1')" maxlength="32" show-word-limit v-model="StorageCategory.CategoryName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('CategoryList.CategoryRemark')" label-height="30px">
            <el-input type="textarea" class="textarea" :rows="5" maxlength="256" show-word-limit v-model="StorageCategory.CategoryRemark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" style="margin-right: 50px;">{{ $t('CategoryList.quit')
          }}</el-button><!--取消-->
          <!-- 添加种类 -->
          <el-button type="primary" @click="append()"> {{ $t('CategoryList.query') }} </el-button><!--确定-->
        </div>
      </el-dialog>
      <!-- 增加修改货品页面 -->
      <el-dialog :title="Title" :visible.sync="huoPinChecked" top="50px" width="1000px" append-to-body>
        <el-form ref="form" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="StorageHuoPin">
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.CategoryID')" prop="CategoryID">
                <TreeSelect :placeholder="$t('CategoryList.sel')" v-model="StorageHuoPin.CategoryID" :value="StorageHuoPin.CategoryID" style="width: 80%;"
                  @selectRoom="StorageCategoryClick" :data="CategoryList">
                </TreeSelect>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.HuoPinCode')">
                <el-input :placeholder="$t('CategoryList.tips1')" maxlength="32" show-word-limit style="width: 80%;" v-model="StorageHuoPin.HuoPinCode">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.HuoPinName')" prop="HuoPinName">
                <el-input :placeholder="$t('CategoryList.tips1')" maxlength="32" show-word-limit style="width: 80%;" v-model="StorageHuoPin.HuoPinName">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.HuoPinModels')">
                <el-input :placeholder="$t('CategoryList.tips1')" maxlength="32" show-word-limit style="width: 80%;" v-model="StorageHuoPin.HuoPinModels">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.HuoPinBarCode')">
                <el-input :placeholder="$t('CategoryList.tips1')" maxlength="128" show-word-limit style="width: 80%;" v-model="StorageHuoPin.HuoPinBarCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.BrandTrademark')">
                <el-input :placeholder="$t('CategoryList.tips1')" maxlength="32" show-word-limit style="width: 80%;" v-model="StorageHuoPin.BrandTrademark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.DefaultPrice')">
                <el-input type="number" :placeholder="$t('CategoryList.DefaultPrice')" maxlength="8" show-word-limit style="width: 80%;"
                  v-model="StorageHuoPin.DefaultPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.CalcUnit')">
                <el-input :placeholder="$t('CategoryList.tips1')" maxlength="8" show-word-limit style="width: 80%;" v-model="StorageHuoPin.CalcUnit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.SafeStockLowerLimit')">
                <el-input type="number" :placeholder="$t('CategoryList.tips1')" maxlength="8" style="width: 80%;" show-word-limit
                  v-model="StorageHuoPin.SafeStockLowerLimit"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.SafeStockUpperLimit')">
                <el-input type="number" :placeholder="$t('CategoryList.tips1')" maxlength="8" style="width: 80%;" show-word-limit
                  v-model="StorageHuoPin.SafeStockUpperLimit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.HuoPinRemark')">
                <el-input type="textarea" class="textarea" rows="5" maxlength="256" show-word-limit v-model="StorageHuoPin.HuoPinRemark"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('CategoryList.HuoPinImage')">
                <input ref="imgfiles" :placeholder="$t('CategoryList.CategoryID')" type="file" @change="selImg($event)" style="width: 0;height: 0;"
                  accept="image/jpg,image/jpeg,image/png" />
                <div v-show="!addUserImg" @click="upImg()" class="avatars">+</div>
                <img @click="upImg()" v-if="addUserImg" :src="addUserImg || StorageHuoPin.HuoPinImage" class="avatar" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="huoPinChecked = false" style="margin-right: 50px;">{{ $t('CategoryList.quit')
          }}</el-button><!--取消-->
          <!-- 添加货品 -->
          <el-button type="primary" :loading="loading" @click="appendHuoPin()">{{ $t('CategoryList.query') }}
          </el-button><!--确定-->
        </div>
      </el-dialog>
    </div>
    <DragTable v-if="TableOk" TableName="StorageHuoPinComponents" :show="TableOk" @message="Message_">
    </DragTable>
  </el-dialog>
</template>
  
<script>
import mixins from "@/mixins";
import {
  // msgTips,
  successTips,
  // 左
  GetStorageCategoryTree,
  GetStorageCategory,
  AddStorageCategory,
  UpdateStorageCategory,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,

  // 右
  GetStorageHuoPinPage,
  updateTime,
  GetStorageHuoPin,
  AddStorageHuoPin,
  UpdateStorageHuoPin,
  // 文件
  upLoadFile,

} from "@/api/user";

export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  components: {},
  props: {
    type: {
      type: Boolean,
      default() {
        return false
      }
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    seltype: {
      type: Boolean,
      default: true
    }
  },
  mixins: [mixins],
  computed: {
    visible() {
      return this.type;
    }
  },
  data() {
    return {
      drawer: false,
      loading2: false,
      tabs: 0,
      menutab: 0,
      ID: "",
      imageNum: '',
      level: "",
      filterText: "",
      icon: '',
      title: "",
      oldImg: '',
      updatetype: false,
      currentPage: 1,
      checked: true,
      upload: false,
      dialogFormVisible: false,
      huoPinChecked: false,
      TableOk: false,
      loading: false,
      tableData: [],
      TableList: [],
      dataTree: [],
      HuoPindata: [],
      form: {
        PageSize: 20,
        PageIndex: 0,
        CategoryID: 0,
        SearchName: ''
      },
      TotalCount: 0,
      addUserImg: '',
      categoryName: '',
      HuoPinList: [],
      multipleSelection: [],
      Title: '',
      rules: {
        CategoryCode: [
          { required: true, message: this.$t('CategoryList.tips9'), trigger: 'blur' }
        ],
        CategoryID: [
          { required: true, message: this.$t('CategoryList.tips10'), trigger: 'blur' }
        ],
        CategoryName: [
          { required: true, message: this.$t('CategoryList.tips11'), trigger: 'blur' }
        ],
        HuoPinCode: [
          { required: true, message: this.$t('CategoryList.tips12'), trigger: 'blur' }
        ],
        HuoPinName: [
          { required: true, message: this.$t('CategoryList.tips13'), trigger: 'blur' }
        ]
      },
      StorageCategory: {
        CategoryID: '',
        CompanyID: '',
        CategoryCode: '',
        CategoryName: '',
        ParentCategoryID: '',
        ParentCategoryID1: '',
        ParentCategoryID2: '',
        ParentCategoryID3: '',
        ParentCategoryID4: '',
        ParentCategoryID5: '',
        ParentCategoryID6: '',
        ParentCategoryID7: '',
        ParentCategoryID8: '',
        ParentCategoryID9: '',
        ParentCategoryID10: '',
        CategoryLevel: '',
        CategoryStatus: '',
        CategoryRemark: '',
        UpdateTime: '',
        CreateTime: ''
      },
      CategoryText: '',
      HuoPinID: '',
      CategoryID: '',
      CategoryList: [],
      StorageHuoPin: {
        ID: '',
        ParentCategoryID: '',
        CompanyID: '',
        CategoryID: '',
        HuoPinCode: '',
        HuoPinName: '',
        HuoPinImage: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        CalcUnit: '',
        SafeStockLowerLimit: '',
        SafeStockUpperLimit: '',
        FactStock: '',
        RFIDStock: '',
        ElseStock: '',
        HuoPinStatus: '',
        HuoPinRemark: '',
        UpdateTime: '',
        CreateTime: '',
        SearchName: '',
        PageSize: '20',
        PageIndex: 0,
      },
      upfile: null,
    };
  },
  mounted() {
    this.getTableColumn();
  },
  methods: {
    show() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.DataList.length; j++) {
            if (this.tableData[i].HuoPinID == this.DataList[j]) {
              this.$refs.tableList.toggleRowSelection(this.tableData[i]);
            }
          }
        }
      }, 1000)
    },
    StorageCategoryClick(e) {
      this.StorageHuoPin.CategoryID = e.id;
      this.StorageHuoPin.CategoryName = e.label;
    },
    handleNodeClick(data) {
      this.form.CategoryID = data.id;
      this.CategoryID = data.id;
      this.level = data.level;
      this.getStorageHuoPin()
    },
    closed() {
      this.$emit('closed')
    },
    //货品增删改查区域
    getStorageHuoPin() {
      if (this.form.PageSize == "") {
        this.form.PageSize = 20;
      }
      if (this.form.PageIndex == "") {
        this.form.PageIndex = 0;
      }
      //普通查询 
      GetStorageHuoPinPage(this.form).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.HuoPindata = res.Data;
          this.TotalCount = res.TotalCount;
          for (let i = 0; i < this.HuoPindata.length; i++) {
            this.HuoPindata[i].CreateTime = updateTime(this.HuoPindata[i].CreateTime);
          }
          this.tableData = this.HuoPindata;
        } else {
          this.TotalCount = 0;
          this.tableData = [];
        }
        this.show();
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    uploadHuoPin() {

    },
    //种类增删改查区域
    getStorageCategory() {
      GetStorageCategoryTree().then((res) => {
        if (res.Code == 0) {
          this.CategoryList = res.Data;
        }
      });
      this.loading2 = true;
      GetStorageCategoryTree().then((res) => {
        if (res.Code == 0 && res.Data != null) {
          let obj = { pid: 0, id: 0, label: this.$t('CategoryList.alltype'), level: 1, status: 1, children: [] };
          res.Data.unshift(obj);
          this.dataTree = res.Data;
          this.loading2 = false;
          if (res.Data) {
            this.CategoryName = res.Data[0].label;
            this.categoryName = res.Data[0].label;
            this.addChecked = false;
            // this.getStorageHuoPin();
          }
        } else {
          this.dataTree = [];
          if (this.dataTree == null && this.dataTree.length == 0) {
            let obj = { pid: 0, id: 0, label: this.$t('CategoryList.alltype'), level: 1, status: 1, children: [] };
            this.dataTree.unshift(obj);
          }
          this.loading2 = false;
        }
      });
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    filterTag(value, row) {
      return row.tag === value;
    },

    Search() { },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.HuoPinID == row.HuoPinID) {
          color = "warning-row";
        }
      });
      return color;
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'StorageHuoPinComponents' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
          this.getStorageCategory();
          this.getStorageHuoPin();
        }
      })
    },
    //刷新
    Message_() {
      this.getTableColumn();
      this.getStorageHuoPin();
      this.TableOk = false;
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageHuoPinComponents', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    //选择整行
    handleRowClick(row) {
      if (!this.seltype) {
        this.$refs.tableList.clearSelection()
      }
      if (this.selectedTable == true) {
        this.$refs.multipleTable.clearSelection(); //单选
      }
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.HuoPinID == row.HuoPinID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.tableList.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.tableList.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    handleSelectionChange(val) {
      this.HuoPinList = val;
      this.multipleSelection = val;
      //获取选中点击的数据 
      if (val.length == 1) {
        this.HuoPinID = val[0].HuoPinID;
        this.checked = false;
      } else {
        this.checked = true;
      }
    },
    queyrHuoPin() {
      this.$emit('addHuopin', this.HuoPinList)
    },

    handleSizeChange(val) {
      this.form.PageSize = val;
      this.getStorageHuoPin();
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);
      this.getStorageHuoPin();
    },
    append() {
      this.$refs.dialog.validate((valid) => {
        if (valid) {
          if (this.Title == this.$t('CategoryList.addCategory')) {
            this.StorageCategory.ParentCategoryID = this.ID;
            AddStorageCategory(this.StorageCategory).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  this.dialogFormVisible = false; //弹窗页面是否开启
                  this.getStorageCategory();
                }
              }
            );
          } else if (this.Title == this.$t('CategoryList.updateCategory')) {
            UpdateStorageCategory({ CategoryID: this.ID, ...this.StorageCategory }).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  this.dialogFormVisible = false; //弹窗页面是否开启
                  this.getStorageCategory();
                }
              }
            );
          }
        }
      })
    },
    addCategory(e, i) {
      for (let i in this.StorageCategory) {
        this.StorageCategory[i] = '';
      }

      if (e) {
        if (i == 0) {
          if (e.pid == 0) {
            this.CategoryText = this.$t('CategoryList.none');
            this.dialogFormVisible = true;
            this.Title = this.$t('CategoryList.addCategory');
            return;
          }
          GetStorageCategory({ CategoryID: e.pid }).then((res) => {
            if (res.Data) {
              this.CategoryText = res.Data.CategoryName;
            } else {
              this.CategoryText = this.$t('CategoryList.none');
            }
          });
        } else {
          this.RoomID = e.id;
          GetStorageCategory({ CategoryID: e.id }).then((res) => {
            if (res.Data) {
              this.CategoryText = res.Data.CategoryName;
            } else {
              this.CategoryText = this.$t('CategoryList.none');
            }
          });
        }
      }
      this.dialogFormVisible = true;
      this.Title = this.$t('CategoryList.addCategory');
    },
    updateCategory(data) {
      if (data.pid == 0) {
        this.CategoryText = this.$t('CategoryList.none');
      } else if (data.pid != 0) {
        GetStorageCategory({ CategoryID: data.pid }).then((res) => {
          if (res.Data) {
            this.CategoryText = res.Data.CategoryName;
          } else {
            this.CategoryText = this.$t('CategoryList.none');
          }
        });
      } else {
        GetStorageCategory({ CategoryID: data.id }).then((res) => {
          if (res.Data) {
            this.CategoryText = res.Data.CategoryName;
          } else {
            this.CategoryText = this.$t('CategoryList.none');
          }
        });
      }
      this.Title = this.$t('CategoryList.updateCategory')
      GetStorageCategory({ CategoryID: data.id }).then(res => {
        if (res.Code == 0) {
          this.StorageCategory = res.Data;
          this.dialogFormVisible = true;
        }
      })

    },
    // 删除种类
    // deleteStorageCategory(data) {
    //   this.$confirm(this.$t('CategoryList.tips7'), this.$t('CategoryList.tipstitle'), {
    //     confirmButtonText: this.$t('CategoryList.query'),
    //     cancelButtonText: this.$t('CategoryList.quit'),
    //     type: 'warning'
    //   }).then(() => {
    //     DeleteStorageCategory({ CategoryID: data.id }).then((res) => {
    //       if (res.Code == 0) {
    //         successTips(res.Message);
    //         this.getStorageCategory();
    //       }
    //     });
    //   }).catch(() => {
    //   });
    // },
    reset() {
      for (let i in this.StorageHuoPin) {
        this.StorageHuoPin[i] = ''
      }
    },
    //照片区域
    upImg() {
      this.$refs.imgfiles.click();
    },
    selImg(e) {
      if (e.target.files.length == 0) {
        return
      }
      let file = e.target.files[0];
      if (this.LimitaTionImg(file)) {
        return;
      }
      this.upfile = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.addUserImg = e.target.result;
      }
    },
    addHuoPin() {
      this.reset();
      if (this.CategoryID == "") {
        this.StorageHuoPin.CategoryID = "";
      } else {
        this.StorageHuoPin.CategoryID = this.CategoryID;
      }
      this.Title = this.$t('CategoryList.add');
      this.huoPinChecked = true;
      this.addUserImg = "";
      this.$refs.tableList.clearSelection();
    },
    updateHuoPin() {
      this.Title = this.$t('CategoryList.update');
      this.addUserImg = '';
      GetStorageHuoPin({ HuoPinID: this.HuoPinID }).then((res) => {
        if (res.Code == 0) {
          this.StorageHuoPin = res.Data;
          this.addUserImg = res.Data.HuoPinImage;
          this.huoPinChecked = true;
        }
      });
    },
    async appendHuoPin() {
      let state = false;
      this.$refs.form.validate((valid) => {
        if (valid) {
          state = true;
        }
      })
      if (!state) return
      this.loading = true;
      if (this.upfile) {
        let formData = new FormData();
        formData.append('file', this.upfile);
        let ret = await upLoadFile(formData);
        if (ret.Code == 0) {
          this.StorageHuoPin.HuoPinImage = ret.Data;
        }
      }
      if (this.Title == this.$t('CategoryList.add')) {
        AddStorageHuoPin(this.StorageHuoPin).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.huoPinChecked = false; //弹窗页面是否开启
              this.getStorageHuoPin();
              this.reset();
            }
          }
        );
      } else if (this.Title == this.$t('CategoryList.update')) {
        UpdateStorageHuoPin(this.StorageHuoPin).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.getStorageHuoPin();
              this.huoPinChecked = false; //弹窗页面是否开启
              this.updatetype = true;
              this.reset();
            }
          }
        );
      }
      this.upfile = null;
      this.loading = false;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 17px 0 !important;
}
._button {
  display: flex;
  justify-content: end;
}
::v-deep .component_footer {
  align-items: end !important;
}
.el-pagination {
  justify-content: end !important;
}
.el-divider.el-divider--vertical {
  height: 583px !important;
}
::v-deep .component_footer {
  align-items: end !important;
}
.el-divider.el-divider--vertical {
  height: 500px;
  margin-top: 25px;
  margin-left: 5px;
}
.el-dialog__title {
  font-size: 16px !important;
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #c00;
  border-radius: 50%; /* 小圆点 */
  margin-right: 12px; /* 和文本之间的距离 */
  vertical-align: middle; /* 垂直居中 */
}
::v-deep .el-tree-node__content {
  margin-top: 10px;
}
::v-deep ._dialog_height {
  width: 1100px !important;
  height: 673px !important;
}
.el-pagination {
  height: 30px;
}
._footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.treeTable {
  overflow: auto;
  max-height: 455px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon-plus {
  border: 1px dashed;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

._filter {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;
  }
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.avatars {
  width: 135px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: -42px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  width: 135px;
  height: 128px;
  display: block;
  margin-top: -38px;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 600px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -20px;
  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;
    margin-top: -20px;
    .content_left {
      width: 30%;
      height: 604px;
      padding: 10px 20px;
      font-size: 19px;
      margin-left: -18px;
      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        .cy li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .companyimg {
      line-height: 150px;
    }

    .content_right {
      height: calc(100vh - 700px);

      position: absolute;
      width: 70%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        .el-input {
          width: 250px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}
</style>