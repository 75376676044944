<template>
  <el-dialog :visible.sync="state" title="" :fullscreen="true" append-to-body @close="close">
    <div class="_edit_box">
      <div class="_box_left">
        <div class="_box_title">
          {{ $t("FormEdit.Component") }}
        </div>
        <div class="_components_list">
          <div class="_list_item" v-for="(item, index) in formCategory" :key="index + '/'" @click="addItem(item)">
            {{ item.formName }}
          </div>
        </div>
      </div>
      <div class="_box_center">
        <div class="_box_title" style="background-color: #fff;text-align: right;padding: 0 10px;">
          <el-button type="primary" size="small" @click="Preview">{{ $t("FormEdit.Preview") }}</el-button>
          <el-button type="primary" size="small" :disabled="formList.length == 0" @click="SaveForm">{{ $t("FormEdit.Save") }}</el-button>
        </div>
        <el-form :label-position="formStyle.labelPosition" label-width="150px"
          style="height: calc(100vh - 90px);overflow-y: auto;">
          <el-row>
            <vuedraggable v-model="formList" @choose="ChooseItem" @update="UpdateItem">
              <transition-group>
                <el-col :span="item.col" :style="`width: ${item.width};`" v-for="(item, idx) in formList"
                  :key="idx + item.formType">
                  <div class="_form_content" :class="{ 'selactive': activeIndex == idx, 'required': item.isRequired }"
                    @click="SelItem(item, idx)">
                    <div class="img_box">
                      <img src="@/assets/img/formedit/formcopy.png" alt="" @click.stop="CopyItem(item)">
                      <img src="@/assets/img/formedit/formdel.png" alt="" @click.stop="DelItem(item, idx)">
                    </div>
                    <el-form-item :label="item.formTitle">
                      <InputItem v-if="item.formType == 'input'" :formData="item"></InputItem>
                      <span class="_error" v-if="item.isRequired && item.formType == 'input'">
                        {{ item.validate.length ? item.validate[0].message : '' }}
                      </span>
                    </el-form-item>
                  </div>
                </el-col>
              </transition-group>
            </vuedraggable>
          </el-row>
        </el-form>
      </div>
      <div class="_box_right">
        <div class="_tabs">
          <div class="_box_title" :class="{ '_active': attributeType == 0 }" @click="attributeType = 0">
            {{ $t("FormEdit.Tips1") }}
          </div>
          <!-- <div class="_box_title" :class="{ '_active': attributeType == 1 }" @click="attributeType = 1">
                        表单属性
                    </div> -->
        </div>
        <div class="_right_content" v-if="obj">
          <div class="_line">
            <span>{{ $t("FormEdit.Title") }}</span>
            <el-input size="small" v-model="obj.formTitle" @input="TitleInput"></el-input>
          </div>
          <div class="_line">
            <span>{{ $t("FormEdit.WidthPercent") }}</span>
            <el-radio-group v-model="obj.style.width" size="small" @change="WidthChange">
              <el-radio-button label="25%">25%</el-radio-button>
              <el-radio-button label="50%">50%</el-radio-button>
              <el-radio-button label="75%">75%</el-radio-button>
              <el-radio-button label="100%">100%</el-radio-button>
            </el-radio-group>
          </div>
          <div class="_line">
            <span>{{ $t("FormEdit.Placeholder") }}</span>
            <el-input size="small" v-model="obj.placeholder" @input="PlaceholderInput"></el-input>
          </div>
          <div class="_line">
            <span>{{ $t("FormEdit.MaxLength") }}</span>
            <el-input size="small" v-model="obj.maxlength" type="number" @input="LengthInput"></el-input>
          </div>
          <div class="_line">
            <span>{{ $t("FormEdit.Validate") }}</span>
            <el-checkbox v-model="obj.isRequired" @change="RequiredChange">{{ $t("FormEdit.Required") }}</el-checkbox>
            <el-input v-if="obj.isRequired" v-model="validateString" @input="ValidateInput"></el-input>
          </div>
        </div>
      </div>
    </div>
    <FormPreview v-if="previewState" :show="previewState" :form-data="{ formList }" @close="previewState = false">
    </FormPreview>
  </el-dialog>
</template>
<script>
import formCategory from './common/formlist';
import InputItem from './common/InputItem.vue';
import FormPreview from './FormPreview.vue';
import vuedraggable from "vuedraggable";
import {
  GetBasicFormField,
  successTips,
  DeleteBasicFormField,
  SetBasicFormField
} from "@/api/user";
export default {
  components: { InputItem, vuedraggable, FormPreview },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    TableKey: {
      type: String
    }
  },
  data() {
    return {
      state: false,
      content: {},
      attributeType: 0,
      formStyle: {
        labelPosition: 'left'
      },
      validateString: '',
      obj: null,
      activeIndex: 0,
      formList: [],
      formCategory: [],
      previewState: false
    }
  },
  mounted() {
    this.formCategory = formCategory;
    this.GetFormField();
  },
  methods: {
    async GetFormField() {
      let res = await GetBasicFormField({ TableKey: this.TableKey })
      if (res.Code == 0 && res.Data.FieldContent) {
        this.formList = JSON.parse(res.Data.FieldContent);
        this.state = this.show;
      } else {
        this.state = this.show;
      }
    },
    Preview() {
      this.previewState = true;
    },
    SaveForm() {
      SetBasicFormField({ TableKey: this.TableKey, TabbleJson: JSON.stringify(this.formList) }).then(res => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.$emit('success');
          this.$emit('close');
        }
      })
      console.log(JSON.stringify(this.formList));
    },
    TitleInput(e) {
      let i = this.activeIndex;
      this.formList[i].formTitle = e;
    },
    PlaceholderInput(e) {
      let i = this.activeIndex;
      this.formList[i].placeholder = e;
    },
    LengthInput(e) {
      let i = this.activeIndex;
      this.formList[i].maxlength = e;
    },
    ValidateInput(e) {
      let i = this.activeIndex;
      this.formList[i].validate = [{ required: true, message: e, trigger: 'blur' }];
    },
    RequiredChange(e) {
      let i = this.activeIndex;
      this.formList[i].isRequired = e;
      this.formList[i].validate = [{ required: true, message: '', trigger: 'blur' }];
    },
    SelItem(val, i) {
      this.activeIndex = i;
      if (val.isRequired) {
        this.validateString = val.validate[0].message || '';
      } else {
        this.validateString = '';
      }
      this.obj = val;
    },
    addItem(val) {
      let obj = JSON.parse(JSON.stringify(val));
      obj.fileID = parseInt(new Date().getTime());
      this.formList.push(obj);
      this.activeIndex = this.formList.length - 1;
      this.obj = obj;
    },
    CopyItem(val) {
      let obj = JSON.parse(JSON.stringify(val));
      obj.fileID = parseInt(new Date().getTime());
      this.formList.push(obj);
      this.activeIndex = this.formList.length - 1;
      if (obj.isRequired) {
        this.validateString = obj.validate[0].message || '';
      }
      this.obj = val;
    },
    async DelItem(val, i) {
      this.obj = this.formList[i - 1];
      this.activeIndex = i - 1;
      if (typeof (val.fileID) == 'string') {
        let res = await DeleteBasicFormField({ TableKey: this.TableKey, fileID: val.fileID });
        if (res.Code == 0) {
          this.formList.splice(i, 1);
          successTips(res.Message);
        }
      } else {
        this.formList.splice(i, 1);
      }
    },
    ChooseItem(e) {
      this.obj = this.formList[e.oldIndex];
      this.activeIndex = e.oldIndex;
    },
    UpdateItem(e) {
      this.activeIndex = e.newIndex;
    },
    WidthChange(e) {
      let i = this.activeIndex;
      if (e == '25%') {
        this.formList[i].col = 6;
      } else if (e == '50%') {
        this.formList[i].col = 12;
      } else if (e == '75%') {
        this.formList[i].col = 18;
      } else if (e == '100%') {
        this.formList[i].col = 24;
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>
<style lang="scss" scoped>
._edit_box {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;

  ._box_title {
    height: 40px;
    width: 100%;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #edeff5;
  }

  ._box_left {
    width: 270px;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid #edeff5;

    ._components_list {
      max-height: calc(100vh - 90px);
      padding: 5px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      ._list_item {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        width: 48%;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #edeff5;
        font-size: 12px;

        &:hover {
          color: #005fff;
          border-style: dashed;
          border-color: #005fff;
          cursor: pointer;
        }
      }
    }
  }

  ._box_center {
    flex: 1;
    height: 100%;
    background-color: #fafafa;

    ._form_content {
      padding: 20px 10px;
      margin: 10px 10px 0;
      cursor: pointer;
      position: relative;

      /deep/.el-form-item {
        margin-bottom: 0;
      }

      ._error {
        display: block;
        height: 12px;
        color: red;
        font-size: 12px;
        line-height: 12px;
      }

      .img_box {
        position: absolute;
        right: 5px;
        top: 5px;
        display: none;

        img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
        }
      }

      &:hover {
        .img_box {
          display: block;
        }
      }
    }

    .selactive {
      background-color: #e4f2fd;
      border-radius: 8px;
    }
  }

  ._box_right {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    border-left: 1px solid #edeff5;

    ._tabs {
      height: 40px;
      width: 100%;
      display: flex;
    }

    ._active {
      border-bottom: 2px solid #005fff;
    }

    ._right_content {
      width: 100%;
      max-height: calc(100vh - 90px);
      overflow-y: auto;
      overflow-x: hidden;

      ._line {
        display: flex;
        flex-direction: column;
        padding: 10px;
      }
    }
  }
}

.required {
  /deep/.el-form-item__label::before {
    content: "*";
    color: red;
  }
}

/deep/.el-form-item__label {
  line-height: 36px !important;
  height: 36px;
  margin-top: 0;
}

/deep/.el-dialog__body {
  padding: 0;
}

/deep/.el-dialog {
  padding: 0;
}

/deep/._fc-l-group {
  display: none;
}

/deep/._fc-l-group:first-child {
  display: block;
}

/deep/._fc-l-item {
  display: none;
}

/deep/._fc-l-item:first-child {
  display: block;
}
</style>