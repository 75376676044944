<template>
    <el-dialog :visible.sync="state" title="" append-to-body @close="$emit('close')">
        <el-form ref="formpreview" :rules="rules" label-position="right" label-width="100px" input-width="100px"
            :model="form">
            <el-row>
                <el-col :span="item.col" v-for="(item, index) in formList" :key="index">
                    <el-form-item :label="item.formTitle" :prop="item.fileID.toString()">
                        <InputItem :form-data="item" :only-read="false" v-model="form[item.fileID.toString()]"
                            @input="(e) => form[item.fileID.toString()] = e">
                        </InputItem>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="_footer">
            <el-button type="primary" @click="close">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import InputItem from './common/InputItem.vue';
export default {
    components: { InputItem },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            rules: {

            },
            formList: [],
            state: false,
            form: {}
        }
    },
    mounted() {
        this.formList = this.formData.formList;
        this.state = this.show;
        let obj = {};
        for (let item of this.formList) {
            let label = item.fileID.toString();
            obj[label] = item.value;
            this.rules[label] = item.validate;
        }
        this.form = obj;
    },
    methods: {
        close() {
            this.$refs['formpreview'].validate((valid) => {
                if (valid) {
                    console.log(this.form);
                    this.$emit('close');
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
._footer {
    width: 100%;
    padding: 10px 50px 10px 0;
    display: flex;
    justify-content: center;
}
</style>