<template>
  <el-dialog :title="$t('BasicDept.SelectAssets')" :visible.sync="state" width="1000px" @close="close" custom-class="_dialog_height" top="8vh" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_right">
          <div class="table">
            <span class="title">{{ title }}</span>
            <div class="head_line">
              <div>
                <el-input type="text" :placeholder="$t('AdminUser.NameOrNumber')" v-model="form.SearchName">
                </el-input>
                <el-button class="button_primary" icon="el-icon-search" @click="getData()">{{ $t('BasicDept.search') }}</el-button><!--搜索-->
              </div>
              <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
                  {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
            </div>
            <div class="content_table">
              <div class="_filter">
              </div>
              <el-table border @select-all="selectAll" @select="select" :data="tableData" height="452px" stripe v-loading="loading"
                @selection-change="handleSelectionChange" :row-class-name="selectRowClassName" ref="tableList" @row-click="handleRowClick" @header-dragend="headerDragend">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
                  <template slot-scope="scope">
                    <ImageView v-if="item.propName == 'GoodsImage'" :url="scope.row.GoodsImage"></ImageView>
                    <span v-else-if="item.propName == 'GoodsStatusName'"
                      :style="`color:${scope.row.GoodsStatusColor};padding: 20px 10px;border-radius: 2px;background-color:${scope.row.GoodsStatusBgColor}`">{{ scope.row.GoodsStatusName
                    }}</span>
                    <span v-else>
                      {{ scope.row[item.propName] }}
                    </span>
                  </template>
                </el-table-column>
                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
                </div>
              </el-table>
            </div> 
            <div class="component_footer">
              <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(AssetGoods.PageIndex + 1)"
                :page-sizes="[20, 50, 100]" :page-size="20" layout="total, prev, pager, next, jumper,sizes" :total="TotalCount">
              </el-pagination>
            </div>
            <el-divider></el-divider>
            <div class="_button">
              <el-button class="button_info" @click="close">{{ $t('AssetAllotAdd.quit')}}</el-button>
              <el-button class="button_primary" @click="addGoods()"> {{ $t('AssetAllotAdd.query') }} </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DragTable v-if="TableOk" TableName="AssetGoodsComponents" :show="TableOk" @message="Message_">
    </DragTable>
  </el-dialog>
</template>
  
<script>
import {
  GetAssetGoodsPage,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,
  updateTime
} from "@/api/user";
import mixins from "@/mixins";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  props: {
    type: {
      type: Boolean,
      default: false
    },
    selectedTable: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    },
    GoodsStatus: {
      type: String,
      default() {
        return '';
      }
    },
    AllotCDeptID: {
      type: [String, Number],
      default: 0
    },
    BelongCDeptID: {
      type: [String, Number]
    },

    UseDeptID: {
      type: Number,
      default: 0
    },
    UseUserID: {
      type: Number,
      default: 0
    },
    BorrowUserID: {
      type: Number,
      default: 0
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    BorrowReturnList: {
      type: Array,
      default() {
        return []
      }
    },
    deptid: {
      type: [String, Number],
      default: ''
    },
    warehouseid: {
      type: [String, Number],
      default: ''
    },
    seltype: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  mixins: [mixins],
  data() {
    return {
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      drawer: false,
      TableOk: false,
      filterText: "",
      icon: '',
      title: "",
      currentPage: 1,
      Title: "",
      checked: true,
      upload: false,
      loading: false,
      dialogFormVisible: false,
      tableData: [],
      TableList: [],
      TotalCount: 0,
      AssetGoods: {
        ID: '',
        CompanyID: '',
        TypeID: '',
        DistrictID: '',
        HuoPinID: '',
        GoodsCode: '',
        GoodsName: '',
        GoodsModels: '',
        GoodsRFIDEPC: '',
        GoodsImage: '',
        CalcUnit: '',
        SerialNO: '',
        GoodsSource: '',
        BelongCDeptID: '',
        BelongCDeptName: '',
        UserManageID: '',
        UserManageName: '',
        ServiceLife: '',
        LifeISAlarm: '',
        EXPWarning: '',
        EXPWarningISAlarm: '',
        EXPAlarm: '',
        EXPAlarmISAlarm: '',
        DateOfPurchase: '',
        UseCDeptID: '',
        UseCDeptName: '',
        UseDeptID: '',
        UseDeptName: '',
        UseDistrictID: '',
        UseDistrictName: '',
        UsePutFactAddress: '',
        UseUserID: '',
        UseUserName: '',
        UseOperator: '',
        UseTime: '',
        BorrowUserID: '',
        BorrowUserName: '',
        BorrowOperator: '',
        BorrowTime: '',
        ReturnOperator: '',
        PlanReturnTime: '',
        ReturnTime: '',
        BorrowISAlarm: '',
        BorrowTipsWarning: '',
        BorrowTipsISAlarm: '',
        CWAssetEntry: '',
        CWAssetType: '',
        TaxAmount: '',
        AmountOfTax: '',
        OriginalValue: '',
        TotalDepreciation: '',
        CurrentValue: '',
        CWServiceLife: '',
        AssetEntryTime: '',
        Supplier: '',
        SupplierContactUser: '',
        SupplierContactPhone: '',
        MaintainPersonName: '',
        MaintainPersonPhone: '',
        MaintainExpirationTime: '',
        MaintainAdnexa: '',
        MaintainRemark: '',
        ZorFAsset: '',
        ZhuGoodsCode: '',
        GoodsStatus: '',
        GoodsRemark: '',
        DateOfProduction: '',
        ExpirationTime: '',
        UpdateTime: '',
        CreateTime: ''
      },
      form: {
        PageIndex: 0,
        PageSize: 20,
        BelongCDeptID: '',
        StartTime: '',
        EndTime: '',
        SearchName: '',
        GoodsStatus: '',
        UseUserID: '',
        BorrowUserID: ''
      },
      ok: false,
      selected: false,
      data: [],
      value: 1,
      addList: [],
      multipleSelection: []
    };
  },
  computed: {
    state: {
      get() {
        return this.type;
      },
      set() {

      }
    },

  },
  mounted() {
    this.getData();
    this.getTableColumn();
  },
  methods: {
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'AssetGoodsComponents' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      this.getTableColumn();
      this.TableOk = false;
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'AssetGoodsComponents', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    select(selection) {
      if (selection.length > 1 && this.seltype == false) {
        let del_row = selection.shift()
        this.$refs.tableList.toggleRowSelection(del_row, false)
      }
    },
    selectAll(selection) {
      if (selection.length > 1 && this.seltype == false) {
        selection.length = 1
      }
    },
    show() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.DataList.length; j++) {
            if (this.tableData[i].GoodsID == this.DataList[j]) {
              this.$refs.tableList.toggleRowSelection(this.tableData[i]);
            }
          }
        }
      }, 1000)
    },

    //查询分页资产
    getData() {
      this.form.GoodsStatus = this.GoodsStatus;
      if (this.BelongCDeptID != 0 && this.BelongCDeptID != "") {
        this.form.BelongCDeptID = this.BelongCDeptID;
      }
      if (this.UseUserID != 0 && this.UseUserID != "") {
        this.form.UseUserID = this.UseUserID;
      }
      if (this.BorrowUserID != 0 && this.BorrowUserID != "") {
        this.form.BorrowUserID = this.BorrowUserID;
      }
      GetAssetGoodsPage(this.form).then((res) => {
        if (res.Code == 0 && res.Data != null) {
          let data;
          if (this.BorrowReturnList.length > 0) {
            data = [...this.BorrowReturnList, ...res.Data]
          } else {
            data = res.Data;
          }
          this.tableData = data;
          this.TotalCount = res.TotalCount;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].DateOfPurchase = updateTime(this.tableData[i].DateOfPurchase);
            this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
          }
          this.show();
        } else {
          if (this.BorrowReturnList.length > 0) {
            this.tableData = this.BorrowReturnList;
          }
          this.show();
          this.TotalCount = 0;
          return;
        }
      });
    },
    close() {
      this.$emit('close')
    },
    addGoods() {
      this.$emit('addGoods', this.addList)
    },
    Search() { },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.GoodsID == row.GoodsID) {
          color = "warning-row";
        }
      });
      return color;
    },
    //选择整行
    handleRowClick(row) {
      console.log(this.seltype);
      if (!this.seltype) {
        this.$refs.tableList.clearSelection()
      }
      if (this.selectedTable == true) {
        this.$refs.tableList.clearSelection(); //单选
      }
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.GoodsID == row.GoodsID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.tableList.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.tableList.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    handleSelectionChange(val) {
      this.addList = val;
      this.multipleSelection = val;
      //获取选中点击的数据 
      if (val) {
        this.checked = false;
      } else {
        this.checked = true;
      }
    },
    handleSizeChange(val) {
      this.form.PageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep ._dialog_height {
  width: 1100px !important;
  height: 733px !important;
}
.el-divider--horizontal{
  margin: 13px 0;
}
._button {
  display: flex;
  justify-content: end;
}
::v-deep .component_footer {
  align-items: end !important; 
}
.el-pagination{
  justify-content: end !important;
}
::v-deep .component_footer {
  align-items: end !important;
}
.but_add {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  padding: 0px 20px !important;
}
/deep/.el-dialog {
  height: 650;
  overflow-y: auto;
}
/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}
.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  position: relative;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 700px);
      padding: 10px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 700px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        .el-input {
          width: 250px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}
.dialog-footer {
  text-align: right;
}
._filter {
  height: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}
</style>