<template>
  <el-dialog :title="DeviceTitle" :visible.sync="dialogFormVisible" top="5%" height="800px" width="1000px" lock-scroll append-to-body @close="close">
    <el-form :rules="rules" ref="form" label-position="left" label-width="100px" input-width="100px" :model="Device">
      <el-row>
        <el-col :span="12">
          <el-form-item label="IME" prop="RFIDDeviceIME">
            <el-input :readonly="disabledChecked" placeholder="IME" @input="changeDraft($event, 'RFIDDeviceIME', 0)" v-model="Device.RFIDDeviceIME"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicDevice.RFIDDeviceName')" prop="RFIDDeviceName"><!--设备名称-->
            <el-input :readonly="disabledChecked" :placeholder="$t('BasicDevice.RFIDDeviceName')" @input="changeDraft($event, 'RFIDDeviceName', 0)"
              v-model="Device.RFIDDeviceName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicDevice.RFIDDeviceTypeName')" prop="RFIDDeviceType"><!--设备类型-->
            <el-select v-if="disabledChecked == false" :readonly="disabledChecked" v-model="Device.RFIDDeviceType" @change="changeDraft($event, 'RFIDDeviceType', 0)"
              :placeholder="$t('BasicDevice.choice')">
              <el-option v-for="item in  RFIDDeviceType" :key="item.value" :label="item.name" :value="item.value" @click.native="SelectDeviceType()">
              </el-option>
            </el-select>
            <el-input v-else :readonly="disabledChecked" :placeholder="$t('BasicDevice.RFIDDeviceTypeName')" @input="changeDraft($event, 'RFIDDeviceTypeName', 0)"
              v-model="Device.RFIDDeviceTypeName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" :label="$t('BasicDevice.RFIDDeviceTypeName')"><!--工作类型-->
            <el-select v-if="disabledChecked == false" :readonly="disabledChecked" v-model="Device.WorkType" @change="changeDraft($event, 'WorkType', 0)"
              :placeholder="$t('BasicDevice.choice')">
              <el-option v-for="item in  WorkType" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <el-input v-else :readonly="disabledChecked" :placeholder="$t('BasicDevice.RFIDDeviceTypeName')" v-model="Device.WorkTypeName"
              @input="changeDraft($event, 'WorkTypeName', 0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" :label="$t('BasicDevice.OutPower')" prop="OutPower"><!--设备功率-->
            <el-input :readonly="disabledChecked" type="number" :placeholder="$t('BasicDevice.OutPower')" v-model="Device.OutPower"
              @input="changeDraft($event, 'OutPower', 0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" class="NetType" :label="$t('BasicDevice.RFIDDeviceNetType')"><!--网络类型-->
            <el-select :readonly="disabledChecked" v-model="Device.RFIDDeviceNetType" :placeholder="$t('BasicDevice.choice')" @change="changeDraft($event, 'RFIDDeviceNetType', 0)"
              v-if="disabledChecked == false">
              <el-option v-for="item in  RFIDDeviceNetType" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <el-input v-else :readonly="disabledChecked" :placeholder="$t('BasicDevice.RFIDDeviceNetType')" v-model="Device.RFIDDeviceNetTypeName"
              @input="changeDraft($event, 'RFIDDeviceNetTypeName', 0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :disabled="disabledChecked" v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" :label="$t('BasicDevice.Direction')"><!--进出方向-->
            <el-select :disabled="disabledChecked" v-model="Device.Direction" :placeholder="$t('BasicDevice.choice')" @change="changeDraft($event, 'Direction', 0)"
              v-if="disabledChecked == false">
              <el-option v-for="item in  Direction" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <el-input v-else :readonly="disabledChecked" :placeholder="$t('BasicDevice.Direction')" v-model="Device.DirectionName"
              @input="changeDraft($event, 'DirectionName', 0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" :label="$t('BasicDevice.TXJianGe')" prop="TXJianGe"><!--通行间隔-->
            <el-input :readonly="disabledChecked" type="number" :placeholder="$t('BasicDevice.TXJianGePrompt')" v-model="Device.TXJianGe"
              @input="changeDraft($event, 'TXJianGe', 0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicDevice.BindingSystem')"
            v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3 || Device.RFIDDeviceType == 11 || Device.RFIDDeviceType == 12" prop="RFIDDeviceDataUse"><!--绑定系统-->
            <el-select v-if="disabledChecked == false" v-model="Device.RFIDDeviceDataUse" :placeholder="$t('BasicDevice.choice')" @change="SelectDeptClick"
              @input="changeDraft($event, 'RFIDDeviceDataUse', 0)">
              <el-option v-for="item in RFIDDeviceDataUse" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <el-input v-else :readonly="disabledChecked" placeholder="" v-model="Device.RFIDDeviceDataUseName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            v-if="(Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3 || Device.RFIDDeviceType == 11 || Device.RFIDDeviceType == 12) && (Device.RFIDDeviceDataUse == 3 || Device.RFIDDeviceDataUse == 4)"
            :label="DeviceTitleName" prop="RFIDDeviceDataID">
            <el-input v-model="Device.RFIDDeviceDataName" readonly class="input-with-select" style="width: 70%">
              <el-button v-if="disabledChecked == false" slot="append" icon="el-icon-thumb" @click="DataClick(Device)"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="blue" :disabled="disabledChecked" v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3 && Device.RFIDDeviceNetType == 2"
        :label="$t('BasicDevice.CameraCapture')"><!--摄像头抓拍-->
        <el-col style="display: flex;align-items: center;justify-content: space-between;">
          <el-radio :disabled="disabledChecked" v-model="Device.CameraCatchOpen" :label="1"
            @change="changeDraft($event, 'CameraCatchOpen', 0)">{{ $t('BasicDevice.Open') }}</el-radio><!--开启-->
          <el-radio :disabled="disabledChecked" v-model="Device.CameraCatchOpen" :label="2"
            @change="changeDraft($event, 'CameraCatchOpen', 0)">{{ $t('BasicDevice.Close') }}</el-radio><!--关闭-->
          <el-input style="flex: 1;margin-right: 60px;" :readonly="disabledChecked" v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3 && Device.RFIDDeviceNetType == 2 && Device.CameraCatchOpen == 1"
            :placeholder="$t('BasicDevice.CameraPrompts')" v-model="Device.BindCamera" @input="changeDraft($event, 'BindCamera', 0)"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item class="blue" v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" :label="$t('BasicDevice.BlueDengOpen')"><!--蓝色灯光-->
        <el-col style="display: flex;align-items: center;justify-content: space-between;">
          <el-radio :disabled="disabledChecked" v-model="Device.BlueDengOpen" :label="1" @change="changeDraft($event, 'BlueDengOpen', 0)">{{ $t('BasicDevice.Open') }}</el-radio>
          <el-radio :disabled="disabledChecked" v-model="Device.BlueDengOpen" :label="2" @change="changeDraft($event, 'BlueDengOpen', 0)">{{ $t('BasicDevice.Close') }}</el-radio>
          <el-input style="flex: 1;margin-right: 60px;" :readonly="disabledChecked" type="number" v-if="Device.BlueDengOpen == 1" :placeholder="$t('BasicDevice.LightDuration')"
            v-model="Device.BlueDengTime" @input="changeDraft($event, 'BlueDengTime', 0)"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item class="red" v-if="Device.RFIDDeviceType >= 1 && Device.RFIDDeviceType <= 3" :label="$t('BasicDevice.RedDengOpen')"><!--红色灯光-->
        <el-col style="display: flex;align-items: center;justify-content: space-between;">
          <el-radio :disabled="disabledChecked" v-model="Device.RedDengOpen" :label="1" @change="changeDraft($event, 'RedDengOpen', 0)">{{ $t('BasicDevice.Open') }}</el-radio>
          <el-radio :disabled="disabledChecked" v-model="Device.RedDengOpen" :label="2" @change="changeDraft($event, 'RedDengOpen', 0)">{{ $t('BasicDevice.Close') }}</el-radio>

          <el-input style="flex: 1;margin-right: 60px;" :readonly="disabledChecked" type="number" v-if="Device.RedDengOpen == 1" label-width="100px"
            :placeholder="$t('BasicDevice.LightDuration')" v-model="Device.RedDengTime" @input="changeDraft($event, 'RedDengTime', 0)"></el-input>
        </el-col>

      </el-form-item>
      <el-form-item v-if="Device.RFIDDeviceType == 4" :label="$t('BasicDevice.OpenFaceLogin')"><!--人脸登录-->
        <el-radio :disabled="disabledChecked" v-model="Device.OpenFaceLogin" :label="1" @change="changeDraft($event, 'OpenFaceLogin', 0)">{{ $t('BasicDevice.Open') }}</el-radio>
        <el-radio :disabled="disabledChecked" v-model="Device.OpenFaceLogin" :label="2" @change="changeDraft($event, 'OpenFaceLogin', 0)">{{ $t('BasicDevice.Close') }}</el-radio>
      </el-form-item>
      <el-form-item v-if="Device.RFIDDeviceType == 4" :label="$t('BasicDevice.OpenCardLogin')"><!--刷卡登录-->
        <el-radio :disabled="disabledChecked" v-model="Device.OpenCardLogin" :label="1" @change="changeDraft($event, 'OpenCardLogin', 0)">{{ $t('BasicDevice.Open') }}</el-radio>
        <el-radio :disabled="disabledChecked" v-model="Device.OpenCardLogin" :label="2" @change="changeDraft($event, 'OpenCardLogin', 0)">{{ $t('BasicDevice.Close') }}</el-radio>
      </el-form-item>
      <!-- <el-row>
                            <! <el-col :span="12">
                              <el-form-item label="设备描述">
                                <el-input :disabled="disabledChecked" rows="5" type="textarea" v-model="Device.RFIDDeviceRemark" style="width: 95%;"></el-input>
                              </el-form-item>
                            </el-col> -->
      <!-- <el-col :span="12"> -->
      <!-- <el-form-item label="设备图片">
                                <input :disabled="disabledChecked" ref="imgfiles" placeholder="设备图片" type="file" @change="selImg($event)"
                                  style="width: 0;height: 0;" />
                                <div v-show="!Device.DeviceImage" @click="upImg()" class="avatars">+</div>
                                <img @click="upImg()" v-if="Device.DeviceImage" :src="addDeviceImg || Device.DeviceImage" class="avatar" />
                              </el-form-item> -->
      <!-- </el-col> -->
      <!-- </el-row> -->
      <el-form-item :label="$t('BasicDevice.RFIDDeviceRemark')"><!--设备描述-->
        <el-input :readonly="disabledChecked" resize="none" class="textarea" rows="3" type="textarea" v-model="Device.RFIDDeviceRemark"
          @input="changeDraft($event, 'RFIDDeviceRemark', 0)" style="width: 98%;"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" class="button_info" style="margin-right: 7px;">{{ $t('BasicDevice.cancel') }}</el-button>
      <el-button v-if="disabledChecked == false" class="button_primary" type="primary" @click="butData('form')">{{ $t('BasicDevice.confirm') }}</el-button>
      <el-button v-if="disabledChecked" class="button_primary" type="primary" @click="dialogFormVisible = false">{{ $t('BasicDevice.confirm') }}</el-button>
    </div>
    <!-- 区域组件 -->
    <SelectRegion v-if="RegionType" :type="RegionType" @close="RegionType = false" @addMember="addRegion" :DataList="RegionList"></SelectRegion>
    <!-- 库房组件 -->
    <SelectWarehouse v-if="Roomtype" :type="Roomtype" @close="Roomtype = false" @addMember="addRoom" :DataList="RoomList">
    </SelectWarehouse>
  </el-dialog>
</template> 
<script>
import {
  successTips,
  AddBasicRFIDDevice,
  UpdateBasicRFIDDevice,
  GetRFIDDeviceType,
  GetRFIDDeviceNetType,
  GetWorkType,
  GetDirection,
  GetRFIDDeviceStatus,
  GetRFIDDeviceDataUse,
  GetStorageWarehouseTree,
  GetBasicRFIDDevice,
  GetAssetDistrictTree
} from "@/api/user";
import draft from '@/mixins/draft';
export default {
  props: {
    form: {
      type: Object,
    },
    Notes: {
      type: Array,
      default() {
        return [];
      },
    },
    DeviceOpertion: {
      type: Object
    },
    ParentDeptID: {
      type: Number,
      default: 0
    },
    ID: {
      type: Number,
      default: 0
    },
    DeviceTitle: {
      type: String,
      default: ''
    },
    Superior: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [draft],
  data() {
    return {
      downUrl: "",
      PName: "",
      model: {},
      list: [],
      CDeptID: [],
      RFIDDeviceType: [],
      WorkType: [],
      RFIDDeviceDataUse: [],
      Direction: [],
      RFIDDeviceStatus: [],
      RFIDDeviceNetType: [],
      RoomList: [],
      DeptIDList: [],
      RFIDDeviceDataID: [],
      RegionList: [],
      disabledChecked: false,
      RegionType: false,
      addUserImg: "",
      draftTitle: ['Device'],
      dialogFormVisible: false,
      Roomtype: false,
      loading: false,
      passWordChecked: false,
      DeviceTitleName: '',
      oldImg: '',
      rules: {
        RFIDDeviceIME: [
          { required: true, message: this.$t('BasicDevice.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 64, message: this.$t('BasicDevice.DeviceLengthIimit'), trigger: 'blur' }
        ],
        RFIDDeviceName: [
          { required: true, message: this.$t('BasicDevice.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicDevice.LengthIimit'), trigger: 'blur' }
        ],
        RFIDDeviceType: [
          { required: true, message: this.$t('BasicDevice.choice'), trigger: 'blur' }
        ],
        RFIDDeviceDataUse: [
          { required: true, message: this.$t('BasicDevice.choice'), trigger: 'blur' }
        ],
        RFIDDeviceDataID: [
          { required: true, message: this.$t('BasicDevice.choice'), trigger: 'blur' }
        ],
        TXJianGe: [
          { required: true, message: this.$t('BasicDevice.choice'), trigger: 'blur' }
        ]
      },
      Device: {
        ID: "",
        RFIDDeviceIME: '',
        StartTime: '',
        EndTime: '',
        SearchName: '',
        PageSize: 20,
        PageIndex: 0,
        CompanyID: "",
        DeviceImage: '',
        RFIDDeviceCode: "",
        RFIDDeviceName: "",
        RFIDDeviceType: "",
        RFIDDeviceNetType: "",
        BindCamera: '',
        IndustryTypeName: "",
        CameraCatchOpen: 1,
        WorkType: "",
        OutPower: '',
        RFIDDeviceDataUse: "",
        RFIDDeviceDataID: "",
        RFIDDeviceDataName: "",
        RFIDDeviceDataUseName: '',
        Direction: 1,
        TXJianGe: "",
        BlueDengOpen: 1,
        BlueDengTime: "",
        RedDengOpen: 1,
        Useto: '',
        Position: '',
        RedDengTime: "",
        OpenFaceLogin: "",
        OpenCardLogin: "",
        ISOnline: "",
        LastTime: "",
        RFIDDeviceStatus: "",
        RFIDDeviceRemark: "",
      },
    };
  },
  mounted() {
    this.disabledChecked = this.checked;
    //工作类型
    GetWorkType().then((res) => {
      if (res.Code == 0) {
        this.WorkType = res.Data;
      }
    });
    //设备绑定系统
    GetRFIDDeviceDataUse().then((res) => {
      if (res.Code == 0) {
        this.RFIDDeviceDataUse = res.Data;
      }
    });
    //进出
    GetDirection().then((res) => {
      if (res.Code == 0) {
        this.Direction = res.Data;
      }
    });
    //设备状态
    GetRFIDDeviceStatus().then((res) => {
      if (res.Code == 0) {
        this.RFIDDeviceStatus = res.Data;
      }
    });
    //设备网络类型
    GetRFIDDeviceNetType().then((res) => {
      if (res.Code == 0) {
        this.RFIDDeviceNetType = res.Data;
      }
    });
    //获取设备类型
    GetRFIDDeviceType().then((res) => {
      if (res.Code == 0) {
        this.RFIDDeviceType = res.Data;
      }
    });
    if (this.DeviceOpertion.Opertion == "Update") {
      GetBasicRFIDDevice({ RFIDDeviceID: this.ID }).then((res) => {
        if (res.Code == 0) {
          this.Device = res.Data;
          if (this.Device.RFIDDeviceNetType == 0) {
            this.Device.RFIDDeviceNetType = "";
          }
          if (this.Device.Direction == 0) {
            this.Device.Direction = "";
          }
          if (this.Device.RFIDDeviceType == 0) {
            this.Device.RFIDDeviceType = "";
          }
          if (this.Device.WorkType == 0) {
            this.Device.WorkType = "";
          }
          if (this.Device.RFIDDeviceDataUse == 0) {
            this.Device.RFIDDeviceDataUse = "";
          }
          if (this.Device.RFIDDeviceDataUse == 3) {
            this.DeviceTitleName = this.$t('BasicDevice.BindRoom');
            //库房渲染
            GetStorageWarehouseTree().then((res) => {
              if (res.Code == 0) {
                this.RFIDDeviceDataID = res.Data;
              }
            });
          }
          if (this.Device.RFIDDeviceDataUse == 4) {
            this.DeviceTitleName = this.$t('BasicDevice.BindRegion');
            //区域渲染
            GetAssetDistrictTree().then((res) => {
              if (res.Code == 0) {
                this.RFIDDeviceDataID = res.Data;
              }
            });
          }
        }
      });
    }
    if (this.DeviceOpertion.Opertion == "Detail") {
      GetBasicRFIDDevice({ RFIDDeviceID: this.ID }).then((res) => {
        if (res.Code == 0) {
          this.Device.CameraCatchOpen == 0 ? 1 : this.Device.CameraCatchOpen;
          this.Device.RFIDDeviceType == 0 ? "" : this.Device.RFIDDeviceType;
          this.Device.WorkType == 0 ? "" : this.Device.WorkType;
          this.Device = res.Data;
          if (this.Device.RFIDDeviceDataUse == 3) {
            this.DeviceTitleName = this.$t('BasicDevice.BindRoom');
          } else if (this.Device.RFIDDeviceDataUse == 4) {
            this.DeviceTitleName = this.$t('BasicDevice.BindRegion');
          }
        }
      });
    }
    if (this.state) {
      this.Device = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
      if (this.Device) {
        if (this.Device.RFIDDeviceDataUse == 3) {
          this.DeviceTitleName = this.$t('BasicDevice.BindRoom');
          //库房渲染
          GetStorageWarehouseTree().then((res) => {
            if (res.Code == 0) {
              this.RFIDDeviceDataID = res.Data;
            }
          });
        } else if (this.Device.RFIDDeviceDataUse == 4) {
          this.DeviceTitleName = this.$t('BasicDevice.BindRegion');
          //区域渲染
          GetAssetDistrictTree().then((res) => {
            if (res.Code == 0) {
              this.RFIDDeviceDataID = res.Data;
            }
          });
        }
      }
    }
    this.dialogFormVisible = this.show;
  },
  methods: {
    //设备点击事件
    DataClick(data) {
      if (data) {
        if (data.RFIDDeviceDataUse == 3) {
          this.RoomList = [];
          this.Roomtype = true;
          this.RoomList.push(data.RFIDDeviceDataID);
        } else if (data.RFIDDeviceDataUse == 4) {
          this.RegionList = [];
          this.RegionType = true;
          this.RegionList.push(data.RFIDDeviceDataID);
        }
      }
    },
    //库房组件事件
    addRoom(e) {
      this.Device.RFIDDeviceDataID = e.WarehouseID;
      this.Device.RFIDDeviceDataName = e.WarehouseName;
      this.changeDraft(e.WarehouseID, 'RFIDDeviceDataID', 0);
      this.changeDraft(e.WarehouseName, 'RFIDDeviceDataName', 0);
      this.Roomtype = false;
    },
    //区域组件事件
    addRegion(e) {
      this.Device.RFIDDeviceDataID = e.DistrictID;
      this.Device.RFIDDeviceDataName = e.DistrictName;
      this.changeDraft(e.DistrictID, 'RFIDDeviceDataID', 0);
      this.changeDraft(e.DistrictName, 'RFIDDeviceDataName', 0);
      this.RegionType = false;
    },
    //设备类型点击事件
    SelectDeviceType() {
      if (this.Device.RFIDDeviceType >= 1 && this.Device.RFIDDeviceType <= 3) {
        this.Device.BlueDengOpen = 1;
        this.Device.RedDengOpen = 1;
      }
    },
    //新增框下拉框搜索
    SelectDeptClick() {
      this.Device.RFIDDeviceDataID = "";
      this.Device.RFIDDeviceDataName = "";
      this.changeDraft(this.Device.RFIDDeviceDataID, 'RFIDDeviceDataID', 0);
      if (this.Device.RFIDDeviceDataUse == 3) {
        this.DeviceTitleName = this.$t('BasicDevice.BindRoom');
        //库房渲染
        GetStorageWarehouseTree().then((res) => {
          if (res.Code == 0) {
            this.RFIDDeviceDataID = res.Data;
          }
        });
      } else if (this.Device.RFIDDeviceDataUse == 4) {
        this.DeviceTitleName = this.$t('BasicDevice.BindRegion');
        //区域渲染
        GetAssetDistrictTree().then((res) => {
          if (res.Code == 0) {
            this.RFIDDeviceDataID = res.Data;
          }
        });
      }

    },
    // 增删方法
    butData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid == false) {
          return;
        } else {
          if (this.DeviceOpertion.Opertion == "Add") {
            // //增加方法  
            AddBasicRFIDDevice(this.Device).then((res) => {
              if (res.Code == 0) {
                successTips(res.Message);
                this.$emit('message', res.Code);
                this.dialogFormVisible = false;
                this.draftState[this.draftIndex] = false;
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
              }
            });
          } else if (this.DeviceOpertion.Opertion == "Update") {
            //修改方法 
            UpdateBasicRFIDDevice(this.Device).then((res) => {
              if (res.Code == 0) {
                successTips(res.Message);
                this.$emit('message', res.Code);
                this.dialogFormVisible = false;
              }
            });
          }
        }
      });
    },
    //关闭页面事件
    close() {
      this.draftState[this.draftIndex] = false;
      this.$emit("close");
    },
  },
};
</script> 
<style lang="scss" scoped>
::v-deep .label.el-form-item__label {
  margin-top: 0px !important;
}

::v-deep .el-form-item__error {
  padding-top: 42px !important;
}

.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: calc(100% - 60px) !important;
  // margin-right: 8%;
}

.el-select {
  width: calc(100% - 60px) !important;
}

.textarea {
  width: calc(100% - 60px) !important;
}

.filter_box ul li {
  margin-bottom: 20px;
  margin-left: 5%;
}

.addScale {
  margin-top: 20px;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 55px;
    }
  }
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .spaner {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.avatars {
  width: 135px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: -40px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  margin-top: -40px;
  width: 135px;
  height: 128px;
  display: block;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }

  .el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/.el-dialog {
    margin-top: 0 !important;
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

.blue .el-input {
  width: 200px;
}

.red .el-input {
  width: 200px;
}

.NetType .el-input {
  width: 300px;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}
</style>